import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";


function EwayBill() {

  const ID = Cookies.get("Login_id");
  const [bills,setBills] = useState([]);
  const navigate = useNavigate();
  const Fetch_Eway_Bills = () =>{
    axios
    .get(`${config.base_url}/Fetch_Eway_bills/${ID}/`)
    .then((res) => {
      if (res.data.status) {
        let bill = res.data.bills;
        setBills([]);
        bill.map((i) => {
          setBills((prevState) => [...prevState, i]);
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  useEffect(() => {
    Fetch_Eway_Bills();
  }, []);
  function exportToExcel() {
    const Table = document.getElementById("EwaybillTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "EwayBills.xlsx");
  }
  function searchTable(){
    var rows = document.querySelectorAll('#EwaybillTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }
  function refreshAll(){
    setBills([])
    Fetch_Eway_Bills();
  }
  function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("EwaybillTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }
  function filterTable(row,filterValue) {
    var table1 = document.getElementById("EwaybillTable");
    var rows1 = table1.getElementsByTagName("tr");

    for (var i = 1; i < rows1.length; i++) {
      var statusCell = rows1[i].getElementsByTagName("td")[row];

      if (filterValue == "all" || statusCell.textContent.toLowerCase() == filterValue) {
        rows1[i].style.display = "";
      } else {
        rows1[i].style.display = "none";
      }
    }

    var table2 = document.getElementById("EwaybillTableExport");
    var rows2 = table2.getElementsByTagName("tr");

    for (var i = 1; i < rows2.length; i++) {
      var statusCell = rows2[i].getElementsByTagName("td")[row];

      if (filterValue == "all" || statusCell.textContent.toLowerCase() == filterValue) {
        rows2[i].style.display = "";
      } else {
        rows2[i].style.display = "none";
      }
    }
  }

    return(
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
  <div className="card radius-15 h-20">
    <div className="row">
      <div className="col-md-12">
        <center><h2 className="mt-3">E-WAY BILLS</h2></center>
        <hr/>
      </div>
    </div>
  </div>
  <div className="card radius-15">
    <div className="card-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex">
              <input type="text" id="search" className="form-control" placeholder="Search.." autocomplete="off" onKeyUp={searchTable} />
              <div className="dropdown ml-1" style={{justifyContent:'left'}}>
                <button type="button" className="btn btn-outline-secondary dropdown-toggle text-grey" data-toggle="dropdown" style={{height:'fit-content',position:'relative',bottom:'10px'}}><i className="fa fa-sort"></i> Sort by</button>
                <div className="dropdown-menu" style={{backgroundColor:'black'}}>
                  <a className="dropdown-item" href="" style={{height:'40px',fontSize:'15px',color:'white'}} onClick={refreshAll}>All</a>
                  <a className="dropdown-item" style={{height:'40px',fontSize:'15px',color:'white',cursor:'pointer'}} onClick={()=>sortTable(3)}>Name</a>
                  <a className="dropdown-item" style={{height:'40px',fontSize:'15px',color:'white',cursor:'pointer'}} onClick={()=>sortTable(2)}>E-Way Bill Number</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
          </div>
          <div className="col-md-5 d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary text-grey" id="exportBtn" style={{height:'fit-content',width:'fit-content'}} onClick={exportToExcel}><i className="fa fa-table"></i> Export To Excel</button>
            <div className="dropdown ml-1">
              <button type="button" className="btn btn-outline-secondary dropdown-toggle text-grey" data-toggle="dropdown" style={{height:'fit-content',width:'fit-content'}}><i className="fa fa-filter"></i> filter by</button>
              <div className="dropdown-menu" style={{backgroundColor:'black'}}>
                <a className="dropdown-item" style={{height:'40px',fontSize:'15px',color:'white',cursor:'pointer'}} onClick={()=>filterTable(6,'all')}>All</a>
                <a className="dropdown-item" style={{height:'40px',fontSize:'15px',color:'white',cursor:'pointer'}} onClick={()=>filterTable(6,'draft')}>Draft</a>
                <a className="dropdown-item" style={{height:'40px',fontSize:'15px',color:'white',cursor:'pointer'}} onClick={()=>filterTable(6,'saved')}>Saved</a>
              </div>
            </div>
            <Link className="ml-1" to="/create_eway_bill"><button type="button" className="btn btn-outline-secondary text-grey" style={{height:'fit-content',width:'fit-content'}}><i className="fa fa-plus font-weight-light"></i> E-WAY BILL</button></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="table-responsive">
      <table className="table table-responsive-md table-hover mt-4" id="EwaybillTable" style={{textAlign:'center'}}>
        <thead>
          <tr>
            <th>SERIAL NO.</th>
            <th>DATE</th>
            <th>E-WAY BILL NO.</th>
            <th>NAME</th>
            <th>MAIL ID</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
            {bills && bills.map((b,index) =>(
              <tr className="clickable-row" onClick={()=>navigate(`/ewaybill_overview/${b.Ewaybill_ID}/`)} style={{ cursor: "pointer" }}>
                <td>{index+1}</td>
                <td>{b.BillDate}</td>
                <td>{b.Ewaybill_No}</td>
                <td>{b.CustomerName}</td>
                <td>{b.CustomerEmail}</td>
                <td>{b.GrandTotal}</td>
                <td>{b.Status}</td>
              </tr>
            ))}
          
        </tbody>
      </table>
    </div>
  </div>
</div>
<table className="EwaybillTable" id="EwaybillTableExport" hidden>
      <thead>
        <tr>
          <th>#</th>
          <th>DATE</th>
          <th>E-WAY BILL NO.</th>
          <th>NAME</th>
          <th>MAIL ID</th>
          <th>AMOUNT</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        {bills && bills.map((i,index)=>(
          <tr>
            <td>{index+1}</td>
            <td>{i.BillDate}</td>
            <td>{i.Ewaybill_No}</td>
            <td>{i.CustomerName}</td>
            <td>{i.CustomerEmail}</td>
            <td>{i.GrandTotal}</td>
            <td>{i.Status}</td>
          </tr>
        ))}
      </tbody>
      </table>
        </>
    )
    
}
export default EwayBill;