import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import * as XLSX from "xlsx";

function Holiday() {
    const ID = Cookies.get('Login_id');
    const [holidays, setHolidays] = useState([]);
    const [originalHolidays, setOriginalHolidays] = useState([]);

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await axios.get(`${config.base_url}/get_holidays/${ID}/`);
                if (response.data.status) {
                    setHolidays(response.data.data);
                    setOriginalHolidays(response.data.data); // store original data
                } else {
                    console.error("Error fetching holidays:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching holidays:", error);
            }
        };

        fetchHolidays();
    }, [ID]);

    const searchTable = () => {
        const searchValue = document.getElementById('search').value.trim().toLowerCase();
        const rows = document.querySelectorAll('#itemsTable tbody tr');
        rows.forEach(row => {
            const text = row.textContent.toLowerCase().replace(/\s+/g, ' ');
            row.style.display = text.includes(searchValue) ? '' : 'none';
        });
    };

    const sortTable = (columnIndex) => {
        const sortedHolidays = [...holidays].sort((a, b) => {
            let aValue, bValue;
            if (columnIndex === 1) {
                aValue = new Date(a.Start_Date).getMonth();
                bValue = new Date(b.Start_Date).getMonth();
            } else if (columnIndex === 2) {
                aValue = new Date(a.Start_Date).getFullYear();
                bValue = new Date(b.Start_Date).getFullYear();
            }
            return aValue > bValue ? 1 : -1;
        });
        setHolidays(sortedHolidays);
    };

    const resetTable = () => {
        setHolidays([...originalHolidays]);
    };

    const exportToExcel = () => {
        const table = document.getElementById("itemsTable");
        const wb = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
        XLSX.writeFile(wb, "Holidays.xlsx");
    };

    const navigate = useNavigate();

    // Track displayed months and generate serial numbers
    const displayedMonths = new Set();
    let serialNumber = 1;

    return (
        <>
            <FinBase />
            <div className="page-content" style={{ backgroundColor: '#2f516f', minHeight: "100vh" }}>
                <div className="card radius-15 h-20">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 className="mt-3">HOLIDAYS</h2>
                            </center>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="card radius-15">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            placeholder="Search.."
                                            autoComplete="off"
                                            onInput={searchTable}
                                        />
                                        <div className="dropdown ml-1" style={{ justifyContent: 'left' }}>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary dropdown-toggle text-grey"
                                                data-toggle="dropdown"
                                                style={{ height: '40px', position: 'relative', bottom: '10px' }}
                                            >
                                                <i className="fa fa-sort"></i> Sort by
                                            </button>
                                            <div className="dropdown-menu" style={{ backgroundColor: 'black' }}>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={resetTable}
                                                    style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                                >
                                                    All
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={() => sortTable(1)}
                                                    style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                                >
                                                    Month
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={() => sortTable(2)}
                                                    style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                                >
                                                    Year
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                                <div className="col-md-5 d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary text-grey"
                                        id="exportBtn"
                                        style={{ height: 'fit-content', width: 'fit-content' }}
                                        onClick={exportToExcel}
                                    >
                                        <i className="fa fa-table"></i> Export To Excel
                                    </button>
                                    <Link to="/add_holiday" className="ml-1">
                                        <button
                                            type="button"
                                            style={{ width: "fit-content", height: "fit-content" }}
                                            className="btn btn-outline-secondary text-grey"
                                        >
                                            <i className="fa fa-plus font-weight-light"></i> Holiday
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-responsive-md table-hover mt-4" id="itemsTable" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>SL.NO.</th>
                                    <th>MONTH</th>
                                    <th>YEAR</th>
                                    <th>HOLIDAYS</th>
                                    <th>WORKING DAYS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {holidays.map((holiday) => {
                                    const month = new Date(holiday.Start_Date).getMonth() + 1; // JavaScript months are 0-based
                                    const year = new Date(holiday.Start_Date).getFullYear();
                                    const monthYear = `${month}-${year}`;
                                    
                                    if (displayedMonths.has(monthYear)) {
                                        return null;
                                    } else {
                                        displayedMonths.add(monthYear);
                                    }
                                    
                                    return (
                                        <tr 
                                            className="clickable-row" 
                                            style={{cursor:'pointer'}} 
                                            onClick={() => navigate(`/view_holidays/${ID}/${month}/${year}`)} 
                                            key={holiday.id}
                                        >
                                            <td>{serialNumber++}</td> {/* Increment serial number */}
                                            <td>{new Date(holiday.Start_Date).toLocaleString('default', { month: 'long' })}</td>
                                            <td>{year}</td>
                                            <td>{holiday.holiday_count}</td>
                                            <td>{holiday.working_days}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Holiday;
