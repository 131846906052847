import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import html2pdf from "html2pdf.js";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/PaymentReport.css";

function Account_Type_Summary() {
    const ID = Cookies.get('Login_id');
    const [accounts,setAccounts] = useState([]);
    const [company,setCompany] = useState([]);
    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
        } else {
            contentDiv.style.display = "block";
        }
    }
    const fetch_accounts = () =>{
        axios.get(`${config.base_url}/Fetch_Account_Type/${ID}/`).then((res)=>{
            if(res.data.status){
                setAccounts(res.data.acc);
                setCompany(res.data.com);
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
    };
    useEffect(()=>{
        fetch_accounts();
      },[])
      function reportPDF(){
        var element = document.getElementById('printReport');
        var opt =
        {
            margin: [0.5, 0.3, 0.3, 0.5],
            filename: 'Account_Type_Summary',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    }
    function ExportToExcel(){
        const Table = document.getElementById("reportTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Account_Type_Summary.xlsx");
    }
    function printSheet() {
        var divToPrint = document.getElementById("printReport");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }
      const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;
  const [status,setStatus] = useState("");
  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      status:status
    };

    axios
      .post(`${config.base_url}/Account_Type_Customized/`, det)
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
            setAccounts(res.data.acc);
            setCompany(res.data.com);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          var em = {
            Id: ID,
            status:status,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(
              `${config.base_url}/Share_Account_Type_Summary/`,
              em
            )
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    
  }
    return(
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
    <div className="card radius-15">
        <div className="card-body">
            <div className="card-title" id="div2">
                <center>
                    <h2 className="text-uppercase" id="headline" style={{textTransform:'uppercase'}}>Account Type Summary </h2>
                </center>
              
            </div>
            <hr />
            <div className="bar">

                <div className="left d-flex justify-content-start">
                    <div className="position-relative mr-2">
                        <button className="btn btn-secondary" onClick={toggleContent} style={{width:'fit-content',height:'fit-content'}}><i className="fa-solid fa fa-gear"></i> Customize Report</button>
                        <div id="contentDiv" className="salescontent" style={{display:'none'}}>
                            <h6>Customize Report</h6>
                            <form className="form reportCustomizeForm px-1" method="get" onSubmit={handleCustomize}>
                                
                                <div className="px-2 w-100">
                                    <label style={{textAlign:'left'}}>Status</label><br />
                                    <select name="status" id="status" className="form-control" value={status} onChange={(e) =>setStatus(e.target.value)}>
                                        <option value="all">All</option>
                                        <option value="Asset">Asset</option>
                                        <option value="Liability">Liability</option>
                                        <option value="Equity">Equity</option>
                                        <option value="Income">Income</option>
                                        <option value="Expense">Expense</option>
                                     
                                    </select>
                                </div>
                                <div className="d-flex px-2 mt-3 mb-4 w-100">
                                    <button type="submit" className="btn btn-outline-light" id="runReportBtn" style={{width:'fit-content',height:'fit-content'}}>Run Report</button>
                                    <button type="reset" onClick={toggleContent}  className="btn btn-outline-light ml-1 w-50" style={{width:'fit-content',height:'fit-content'}}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                <div className="right d-flex">

                    <a className="btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" onClick={reportPDF} style={{width:'fit-content',height:'fit-content'}}> &nbsp;PDF</a> 
                    <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" onClick={printSheet}  style={{width:'fit-content',height:'fit-content'}}>&nbsp;Print</a>
                    <a className="ml-2 btn btn-outline-secondary text-grey fa fa-table" role="button" id="exportBtn" onClick={ExportToExcel} style={{width:'fit-content',height:'fit-content'}}>&nbsp;Export</a>
                    <div className="dropdown p-0 nav-item"  id="shareBtn">
                        <li  className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt" data-toggle="dropdown" style={{width:'fit-content',height:'fit-content'}}>&nbsp;Share</li>
                        <ul className="dropdown-menu" style={{backgroundColor:'black'}} id="listdiv">
                        <a href={shareUrl} target="_blank" rel="noopener noreferrer"><li style={{textAlign:'center'}} >Whatsapp</li></a>
                            <li style={{textAlign:'center',color:'#e5e9ec',cursor:'pointer'}} id="toggleEmailModal" data-toggle="modal" data-target="#shareToEmail">Email</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div className="card radius-15 print-only" id="pdf-card">
        <div className="card-body">
            <div className="container-fluid">

                <div  id="printReport"  className="printReportTemplate" style={{display:'block'}}>
                    <div className="my-5 page" size="A4" >
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary" >
                                <div className="col-12">
                                        <center className="h5 text-white"><b>{company}</b></center>
                                        <center className="h3 text-white"><b>ACCOUNT TYPE SUMMARY</b></center>
                                       
                                        <center>  <div id="selectedDates" className="mt-2"></div> </center>
                                       
                                </div>  
                            </div>
                            <div className="row px-1 py-1">
                                <div className="col-12">

                                    <section className="product-area mt-2">
                                        <table className="table table-responsive-md mt-4 table-hover" id="reportTable" style={{width:'100%',borderCollapse:'collapse'}}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">ACCOUNT NAME</th>
                                                    <th className="text-center">ACCOUNT CODE</th>
                                                    <th className="text-center">ACCOUNT TYPE</th>
                                                    <th className="text-center">DEBIT AMOUNT</th>
                                                    <th className="text-center">CREDIT AMOUNT</th>
                                                    

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accounts && accounts.map((p,index) =>(
                                                    <tr>
                                                    <td className="text-center">{p.account_name}</td>
                                                    <td className="text-center">{p.account_code}</td>
                                                    <td className="text-center">{p.account_type}</td>
                                                    <td className="text-center">0.0</td>
                                                    <td className="text-center">0.0</td>
                                                </tr>
                                                ))}
                                               
                                                   
        
                                            </tbody>
                                        </table>
                                    </section>
                        
                                    
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Share To Email Modal --> */}
<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
    )
    
}
export default Account_Type_Summary;