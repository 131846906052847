import React, { useEffect, useState, useRef } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import './main.css';

function View_Attendence() {
    const {id,mn,yr} = useParams();

    const ID = Cookies.get('Login_id');
    function calendar() {
        document.getElementById("overviewBtn").style.display = 'none';
        document.getElementById("templateBtn").style.display = 'none';
        // document.getElementById("calendarLink").style.display = 'block';
        document.getElementById("calendarLink").style.backgroundColor = 'rgba(33, 66, 92  )';
        document.getElementById("holidayListLink").style.backgroundColor = 'transparent';
        document.getElementById("cal_list").style.display = 'none';
        document.getElementById("cal").style.display = 'block';
    }
    function attendence() {
        // document.getElementById("holidayListLink").style.display = 'block';
        document.getElementById("holidayListLink").style.backgroundColor = 'rgba(33, 66, 92  )';
        document.getElementById("overviewBtn").style.display = 'block';
        document.getElementById("templateBtn").style.display = 'block';
        document.getElementById("calendarLink").style.backgroundColor = 'transparent';
        document.getElementById("cal_list").style.display = 'block';
        document.getElementById("cal").style.display = 'none';
    }
    function overview() {
        document.getElementById("overviewBtn").style.backgroundColor = 'rgba(22,37,50,255)';
        document.getElementById("templateBtn").style.backgroundColor = 'transparent';
        document.getElementById("leave").style.display = 'none';
        document.getElementById("overview").style.display = 'block';
        document.getElementById("pdfBtn").style.display = 'none';
        document.getElementById("printBtn").style.display = 'none';
        document.getElementById("shareBtn").style.display = 'none';
        
    }
    function template() {
        document.getElementById("overviewBtn").style.backgroundColor = 'transparent';
        document.getElementById("templateBtn").style.backgroundColor = 'rgba(22,37,50,255)';
        document.getElementById("leave").style.display = 'block';
        document.getElementById("overview").style.display = 'none';
        document.getElementById("pdfBtn").style.display = 'block';
        document.getElementById("printBtn").style.display = 'block';
        document.getElementById("shareBtn").style.display = 'block';
        
    }
    const [details,setDetails] = useState([]);
    const [month,setMonth] = useState([]);
    const [name,setName] = useState([]);
    const [holiday,setHoliday] = useState([]);
    const [wdays,setWdays] = useState([]);
    const [leave,setLeave] = useState([]);
    const [year,setYear] = useState([]);
    const [comments, setComments] = useState([]);
    const [sdate,setDate] = useState([]);
    const [edate,setEDate] = useState([]);
    const fetchAttendenceDetails = () =>{
        axios
          .get(`${config.base_url}/fetch_attendence_details/${id}/${ID}/${mn}/${yr}/`)
          .then((res) => {
            if (res.data.status) {
              var attend = res.data.attd;
              var month = res.data.month;
              var name = res.data.name;
              var year = res.data.year;
              var holiday = res.data.holiday;
              var working = res.data.working;
              var leave = res.data.absent;
              var comments = res.data.comments;
              var sdate = res.data.sdate;
              var edate = res.data.edate;
              
              setComments([]);
              comments.map((c) => {
                setComments((prevState) => [...prevState, c]);
              });
            }
            setDetails(attend);
            setMonth(month);
            setName(name);
            setHoliday(holiday);
            setYear(year);
            setWdays(working);
            setLeave(leave);
            setDate(sdate);
            setEDate(edate);
           
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
    };
    useEffect(() => {
        fetchAttendenceDetails();
      }, []);
      function ExportAsPDF() {
        const container = document.getElementById('holidayList');
        // Save the original styles
        const originalStyles = {
            backgroundColor: container.style.backgroundColor,
            color: container.style.color,
        };

        // Set the container's background color to white and text color to black for specific columns
        const headerCells = container.querySelectorAll('thead th');
        const dataCells = container.querySelectorAll('tbody td');


        headerCells.forEach(cell => {
            // cell.style.backgroundColor = '';
            cell.style.color = 'black';
        });

        dataCells.forEach(cell => {
            // cell.style.backgroundColor = 'white';
            cell.style.color = 'black';
        });

        // const lastColumnIndex = container.rows[0].cells.length - 1; // Assuming the table has a header row
        // Array.from(container.rows).forEach(row => {
        //     row.cells[lastColumnIndex].style.display = 'none';
        // });

        // Add margin to the PDF
        const margin = 10; // Adjust as needed
        const pdfOptions = {
            margin: [margin, margin, margin, margin], // top, right, bottom, left
            filename: `${name} ${month}-leaves.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' }, // Adjust format and orientation
        };

        html2pdf().from(container).set(pdfOptions).save().then(() => {
            // Display the last column again after downloading the PDF
            // Array.from(container.rows).forEach(row => {
            //     row.cells[lastColumnIndex].style.display = 'flex';
            // });

            headerCells.forEach(cell => {
                // cell.style.backgroundColor = '';
                cell.style.color = originalStyles.color;
            });

            dataCells.forEach(cell => {
                // cell.style.backgroundColor = '';
                cell.style.color = originalStyles.color;
            });

            container.style.backgroundColor = originalStyles.backgroundColor;
            container.style.color = originalStyles.color;
        });
    }
    function printSection() {
        window.print()
    }
    const [emailIds, setEmailIds] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    function handleShareEmail(e) {
        e.preventDefault();
    
        var emailsString = emailIds.trim();
    
        var emails = emailsString.split(",").map(function (email) {
          return email.trim();
        });
    
        var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    
        var invalidEmails = [];
        if (emailsString === "") {
          alert("Enter valid email addresses.");
        } else {
          for (var i = 0; i < emails.length; i++) {
            var currentEmail = emails[i];
    
            if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
              invalidEmails.push(currentEmail);
            }
          }
    
          if (invalidEmails.length > 0) {
            alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
          } else {
            var em = {
              id: id,
              Id: ID,
              email_ids: emailIds,
              email_message: emailMessage,
            };
            axios
              .post(`${config.base_url}/Share_Attendence_Email/`, em)
              .then((res) => {
                if (res.data.status) {
                  Toast.fire({
                    icon: "success",
                    title: "Shared via mail.",
                  });
                  setEmailIds("");
                  setEmailMessage("");
                }
              })
              .catch((err) => {
                console.log("ERROR=", err);
                if (
                  err.response &&
                  err.response.data &&
                  !err.response.data.status
                ) {
                  Swal.fire({
                    icon: "error",
                    title: `${err.response.data.message}`,
                  });
                }
              });
          }
        }
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      const currentUrl = window.location.href;
      const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
      )}`;
      const navigate = useNavigate();
      function handleDelete(id) {
        Swal.fire({
          title: `Delete Attendence - ${name}?`,
          text: "Data cannot be restored.!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#3085d6",
          confirmButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`${config.base_url}/Delete_Attendence/${id}/`)
              .then((res) => {
                console.log(res);
    
                Toast.fire({
                  icon: "success",
                  title: "Attendence Deleted.",
                });
                navigate("/attendence");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
      const [comment, setComment] = useState("");
  const saveComment = (e) => {
    e.preventDefault();
    var cmt = {
      Id: ID,
      id: id,
      comments: comment,
    };
    axios
      .post(`${config.base_url}/Add_attendence_comment/`, cmt)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Comment Added",
          });
          setComment("");
          fetchAttendenceDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  function deleteComment(id) {
    Swal.fire({
      title: "Delete Comment?",
      text: "Are you sure you want to delete this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/deleteComment/${id}/`)
          .then((res) => {

            Toast.fire({
              icon: "success",
              title: "Comment Deleted",
            });
            fetchAttendenceDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
  const [events, setEvents] = useState()
  const [leaves,setLeaves] = useState()
  const [initialDate, setInitialDate] = useState(null);
  const Get_calendar_data = () =>{
    axios
      .get(`${config.base_url}/calendar_data/${id}/${mn}/${yr}/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const holiday = res.data.events;
          const leaves = res.data.leaves;
          const today = res.data.end;
          setInitialDate(today);
          const combinedEvents = [];
          combinedEvents.push(
            holiday.map((event) => ({
              title: event.title, 
              start: event.start, 
              end: event.end,
              backgroundColor:'red',
              
            
             
            }))
          );
          combinedEvents.push(
            leaves.map((event) => ({
              title: event.title, 
              start: event.start, 
              end: event.end,
              backgroundColor:'gray',

            }))
          );
          // const db1Events = holiday.map(event => ({ ...event, color: 'transparent',}));
          // const db2Events = leaves.map(event => ({ ...event, color: 'transparent', }));
          
          
          // const combinedEvents = [...db1Events, ...db2Events];
          // setEvents(combinedEvents);
          setEvents(combinedEvents.flat());
          
          
        }
        
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }
  useEffect(() =>{
    Get_calendar_data();
  },[] )

    return(
      
        <>
        <FinBase />
      <div className="page-content" style={{backgroundColor:' #2f516f', minHeight: "100vh" }}>
        <div className="row radius-15">
          <div id="calendarDiv" className="col ">
            <center>
            <h3>
            <a  role="button" id="calendarLink" 
                    style={{padding:'10px 10%',borderTopRightRadius:'15px',borderTopLeftRadius:'15px',height: "fit-content",width: "fit-content",backgroundColor:'rgba(33, 66, 92  )'}} onClick={() => calendar()}>Calendar</a>
            </h3></center>

          </div>
          <div id="holidayListDiv" className="col">
              <center>
              <h3>
                    <a  role="button" id="holidayListLink"
                    style={{padding:'10px 10%',borderTopRightRadius:'15px',borderTopLeftRadius:'15px',height: "fit-content",width: "fit-content"}} onClick={() => attendence()}>Attendance
                    List</a>
                    </h3></center>

          </div>
        </div>
        <div className="card radius-15">
          <div className="card-body">
            <div className="row" id="cal">
              <div className="col" id="calendar">
                {initialDate && (
                    <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} initialView="dayGridMonth" height={600} initialDate={initialDate}
                    dayCellContent={(arg) => {
                      if (!events) return arg.dayNumberText;
              
                      const currentEvents = events.filter(event => {
                        const start = new Date(event.start);
                        const end = new Date(event.end || event.start); 
                        const current = new Date(arg.date);
                        
                        return current >= start && current <= end;
                      });
                      return (
                        <div>
                          <h6 style={{textAlign:'right'}}>{arg.dayNumberText}</h6>
                              <ul>
                            {currentEvents.map((event, index) => (
                              <h6 style={{position:'relative',right:'15px',top:'40px',width:'220px',backgroundColor: event.backgroundColor,borderRadius:'20px',textAlign:'center'}}>{event.title}</h6>
                              
                            ))}
                          </ul>
                        </div>
                      );
                  }} />
                )}
                

              </div>

            </div>
            <div class="row" id="cal_list" style={{display:'none'}}>
              <div className="card-body" style={{width:'100%'}}>
                <div className="card-title">
                  <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                          <a style={{padding:'10px',cursor:'pointer',borderRadius:'1vh',backgroundColor:'rgba(22,37,50,255)',height: "fit-content",width: "fit-content"}} id="overviewBtn" onClick={() => overview()}>Overview</a>
                          <a style={{padding:'10px',cursor:'pointer',borderRadius:'1vh',height: "fit-content",width: "fit-content"}} onClick={() => template()} id="templateBtn"> Leaves</a>

                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <a href="#" className="ml-2 btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" style={{display:'none',height: "fit-content",width: "fit-content"}} onClick={ExportAsPDF} title="Download PDF"> &nbsp;PDF</a>
                            <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" onClick={printSection} data-employee-id="{{employ.id}}" title="Print" style={{display:'none',height: "fit-content",width: "fit-content"}}>&nbsp;Print</a> 
                              <div className="dropdown p-0 nav-item" id="shareBtn" style={{display:'none'}}>
                                <li className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt" data-toggle="dropdown" style={{height: "fit-content",width: "fit-content"}}>&nbsp;Share</li>
                                <ul className="dropdown-menu" style={{backgroundColor:'black'}} id="listdiv">
                                <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                          <li style={{textAlign:'center',color:'#e5e9ec',cursor:'pointer'}}>Whatsapp</li></a>
                                          <li style={{textAlign:'center',color:'#e5e9ec',cursor:'pointer'}} data-toggle="modal" data-target="#shareBillEmail">Email</li>
                                              
                                </ul>          
                              </div>          
                        </div>

                    </div>

                  </div>
                  <center>
                        <h3 className="card-title" id="card-title"> OVERVIEW</h3>
                        
                    </center>

                </div>

              </div>
              <div className="card card-registration card-registration-2" style={{borderRadius:'15px'}}>
                  <div className="card-body p-0">
                    <div id="overview">
                      <div className="row g-0 mx-0">
                        <div className="pb-3 px-2" style={{width:'100%'}}>
                          <div className="card-body">
                            <div className="card-title">
                              <div className="row">
                                <div className="col mt-3">
                                     <h2 className="mb-0">{name}</h2>

                                </div>

                              </div>

                            </div>
                              <hr />
                              <div className="row mb-4 d-block justify-content-between align-items-center">
                                <div className="col-md-2 mt-3">
                                  <h6 className="mb-0">Total Holidays : { holiday}</h6>
                                </div>
                                <br />
                                <div className="col-md-2 mt-3">
                                  <h6 className="mb-0">Total Working Days : {wdays}</h6>
                                </div>
                                <br />
                                <div className="col-md-2 mt-3">
                                  <h6 className="mb-0">Total Leave : { leave }</h6>
                                </div>

                              </div>
                                <hr className="my-4" />

                          </div>

                        </div>

                      </div>

                    </div>
                    <div id="leave" style={{display:'none'}}>
                      <div id="whatToPrint" className="print-only" style={{width:'100%',border:'none'}}>
                        <center>
                          <h3 id="headDing"><span id="monthName">{month}</span>,{year}</h3>
                        </center><br />
                        
                          <br /><br />
                          <table className="table"  id="holidayList">
                            <thead className="thead">
                              <tr style={{fontSize:'large'}}>
                                <th>Sl No.</th>
                                <th>REASON</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details && details.map((d,index) =>(
                                <tr style={{fontSize:'large'}}>
                                  <td style={{width:'50px'}}>{index+1}</td>
                                  <td>{d.Reason}</td>
                                  <td>{d.Start_Date}</td>
                                  <td>{d.End_Date}</td>
                                  <td style={{display:'flex',alignContent:'space-between',paddingLeft:'33%'}}>
                                    <a role="button" onClick={() =>handleDelete(d.id)}><i className="fa fa-solid fa fa-trash" style={{color:'crimson',position:'relative',top:'4px',fontSize:'22px'}}></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link to={`/edit_attendence/${d.id}/${mn}/${yr}/${id}/`} id="editBtn"  role="button"  style={{ height: "fit-content", width: "fit-content" }}><i className="fa fa-solid fas fa-edit" style={{color:'cyan',fontSize:'20px',position:'relative',top:'5px'}} ></i></Link>&nbsp;&nbsp;&nbsp;
                                    <div className="dropdown" style={{marginTop:'-7px',height:'40px'}}>
                                      <button type="button" className="btn btn-grey" data-toggle="dropdown" style={{width:'30px',height:'30px',position:'relative',bottom:'5px'}}><i className="fa fa-solid fa fa-ellipsis-v"></i></button>
                                      <div className="dropdown-menu" style={{backgroundColor:'black',borderRadius:'2px'}}>
                                        <Link to={`/attendence_history/${d.id}/${mn}/${yr}/${id}/`} id="historyBtn" role="button" style={{ height: "fit-content", width: "fit-content",position:'relative',left:'30px',fontSize:'15px' }}>&nbsp;&nbsp;History</Link>
                                        <a className="dropdown-item" style={{height:'fit-content',fontSize:'15px',color:'white',cursor:'pointer'}} data-toggle="modal" data-target="#commentModal" id="commentView{{i.id}}">Comments</a>

                                      </div>

                                    </div>
                                  </td>

                                </tr>
                              ))}
                            </tbody>

                          </table>

                        

                      </div>

                    </div>

                  </div>
              </div>
                
            </div>

          </div>

        </div>
      </div>
      {/* <!-- modal --> */}
      

<div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.Comments}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      <div className="modal fade" id="shareBillEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Leave Statement Via Email</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    

        </>
    )
    
    
}
export default View_Attendence;