import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
import config from "../../../functions/config";
import "../../styles/PaymentReport.css";

function Payment_Made_summary() {
    const ID = Cookies.get('Login_id');
    const [payment,setPayment] = useState([]);
    const [comp,setCompany] = useState([]);
    const [totpay,setTotpay] = useState([]);
    const [totamount,setTotamount] = useState([]);
    const [totbal,setTotbal] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState("");

    const fetchPayments = () =>{
        axios.get(`${config.base_url}/Fetch_Payment_made_summary/${ID}/`).then((res)=>{
            if(res.data.status){
              var pay = res.data.payment;
              var comp = res.data.company;
              var totpay = res.data.totpay;
              var totamount = res.data.totamount;
              var totbal = res.data.totbal;
              setPayment(pay);
              setCompany(comp);
              setTotpay(totpay);
              setTotamount(totamount);
              setTotbal(totbal);
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
    };
    useEffect(()=>{
        fetchPayments();
      },[])

    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
        } else {
            contentDiv.style.display = "block";
        }
    }
    function reportPDF() {
        var st = startDate;
        var en = endDate;
        var date = "";
        if (st != "" && en != "") {
          date = `_${startDate}` + "_" + `${endDate}`;
        }
        var element = document.getElementById("printReport");
        var opt = {
          margin: [0.5, 0.3, 0.3, 0.5],
          filename: "Report_Payment_Made_Details" + date,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
      }
    function printSheet() {
        var divToPrint = document.getElementById("printReport");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }

      function ExportToExcel() {
        var st = startDate;
        var en = endDate;
        var date = "";
        if (st != "" && en != "") {
          date = `_${startDate}` + "_" + `${endDate}`;
        }
        const Table = document.getElementById("reportTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Report__Payment_Made_Details" + date + ".xlsx");
      }
    const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;
  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      status: status,
    };

    axios
      .post(`${config.base_url}/fetch_payment_made_customized/`, det)
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
            //   var pay = res.data.payment;
            //   var comp = res.data.company;
            //   var totpay = res.data.totpay;
            //   var totamount = res.data.totamount;
            //   var totbal = res.data.totbal;
            setPayment(res.data.payment);
            setCompany(res.data.company);
            setTotpay(res.data.totpay);
            setTotamount(res.data.totamount);
            setTotbal(res.data.totbal);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setStatus(res.data.filterStatus);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();
    var st = startDate;
    var end = endDate;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          var em = {
            Id: ID,
            start: startDate,
            end: endDate,
            status: status,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(
              `${config.base_url}/share_payment_made_report/`,
              em
            )
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
    return(
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
        <div className="card radius-15">
    <div className="card-body">
        <div className="card-title text-center" id="div2">
          <center>
            <h2 className="text-uppercase" id="headline" style={{ textTransform: 'uppercase', width: 'fit-content',position:'relative',right:'15px' }}>Payment Made Summary</h2>
            </center>
        </div>
        <hr />
        <div className="bar d-flex flex-column flex-md-row justify-content-between align-items-md-center">
            <div className="col-md-6 col-12 d-flex justify-content-md-start justify-content-center mb-3">
                <button className="btn btn-secondary" onClick={() => toggleContent()} style={{ width: 'fit-content', height: 'fit-content' }}>
                    <i className="fa-solid fa fa-gear"></i> Customize Report
                </button>
                <div id="contentDiv" className="salescontent" style={{ display: 'none' }}>
                    <h6>Customize Report</h6>
                    <form onSubmit={handleCustomize} className="form reportCustomizeForm px-1">
                        <div className="px-2 w-100">
                            <label>From</label><br />
                            <input name="from_date" className="inputdate form-control" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
                        </div>
                        <div className="px-2 w-100">
                            <label>To</label><br />
                            <input name="to_date" type="date" className="inputdate form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
                        </div>
                        <div className="px-2 w-100">
                            <label>Status</label><br />
                            <select name="status" id="salesTransactions" className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="all">All</option>
                                <option value="Saved">Save</option>
                                <option value="Draft">Draft</option>
                            </select>
                        </div>
                        <div className="d-flex px-2 mt-3 mb-4 w-100">
                            <button type="submit" className="btn btn-outline-light" id="runReportBtn">Run Report</button>
                            <button type="reset" onClick={() => toggleContent()} className="btn btn-outline-light ml-1 w-50">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="right d-flex flex-wrap justify-content-center justify-content-md-end">
                <a className="btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" onClick={reportPDF} style={{width:'fit-content',height:'fit-content'}}> &nbsp;PDF</a>
                <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" onClick={printSheet} style={{width:'fit-content',height:'fit-content'}}>&nbsp;Print</a>
                <a className="ml-2 btn btn-outline-secondary text-grey fa fa-table" role="button" id="exportBtn" onClick={ExportToExcel} style={{width:'fit-content',height:'fit-content'}}>&nbsp;Export</a>
                <div className="dropdown p-0 nav-item ml-2" id="shareBtn">
                    <li className="dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt" data-toggle="dropdown" style={{width:'fit-content',height:'fit-content'}}>&nbsp;Share</li>
                    <ul className="dropdown-menu" style={{ backgroundColor: 'black' }} id="listdiv">
                        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                            <li style={{ textAlign: 'center' }}>Whatsapp</li>
                        </a>
                        <li style={{ textAlign: 'center', color: '#e5e9ec', cursor: 'pointer' }} id="toggleEmailModal" data-toggle="modal" data-target="#shareToEmail">Email</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

    <div className="card radius-15 print-only" id="pdf-card">
        <div className="card-body">
            <div className="container-fluid">

                <div  id="printReport"  className="printReportTemplate" style={{display:'block'}}>
                    <div className="my-5 page" size="A4" >
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary" >
                                <div className="col-12">
                                         <center className="h5 text-white"><b>{comp}</b></center>
                                        <center className="h3 text-white"><b>PAYMENT MADE SUMMARY</b></center>
                                       
                                        <center>  <div id="selectedDates" className="mt-2"></div> </center>
                                       
                                </div>  
                            </div>
                            <div className="row px-1 py-1">
                                <div className="col-12">

                                    <section className="product-area mt-2">
                                        <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">DATE</th>
                                                    <th className="text-center">PAYMENT NO.</th>
                                                    <th className="text-center">VENDOR NAME</th>
                                                    <th className="text-center">PAYMENT METHOD</th>
                                                    <th className="text-center">Total AMOUNT  MADE</th>
                                                    <th className="text-center">TOTAL PAYMENT MADE</th>
                                                    <th className="text-center"> BALANCE AMOUNT MADE</th>
                                                    <th className="text-center">STATUS</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                            {payment && payment.map((p,index) =>(
                                                    <tr>
                                                    <td className="text-center">{p.date}</td>
                                                    <td className="text-center">{p.payno}</td>
                                                    <td className="text-center">{p.name}</td>
                                                    <td className="text-center">{p.paymethod}</td>
                                                    <td className="text-center">{p.totalamount }</td>
                                                    <td className="text-center">{p.totalpayment}</td>
                                                    <td className="text-center">{p.totalbalance}</td>
                                                    <td className="text-center">{p.status}</td>
                                                </tr>
                                                ))}
                                                <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="totalAmount">Total Amount:{totamount}</td>
                                                        <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="totalPayment">Total payment:{totpay} </td>
                                                        <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="totalBalance">Total Balance:{totbal} </td>
                                                        <td></td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </section>
                        
                                    
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

{/* <!-- Share To Email Modal --> */}
<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
    )
    
}
export default Payment_Made_summary;