import React, { useEffect, useState, useRef } from 'react'
import FinBase from "../FinBase";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import "../../styles/horizontalprofitandloss.css";



function HorizontalProfitAndLoss() {

    const ID = Cookies.get("Login_id");

    const [companyName, setCompanyName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [items, setItems] = useState("");
    const [itemsList, setItemsList] = useState("");
    const [totalOpeningStock, setTotalOpeningStock] = useState("");
    const [totalClosingStock, setTotalClosingStock] = useState("");
    const [totalPurchaseAccounts, setTotalPurchaseAccounts] = useState("");
    const [totalIndirectExpense, setTotalIndirectExpense] = useState("");
    const [totalExpense, setTotalExpense] = useState("");
    const [totalDiscountPaid, setTotalDiscountPaid] = useState("");
    const [totalSalesAccounts, setTotalSalesAccounts] = useState("");
    const [totalDiscountReceived, setTotalDiscountReceived] = useState("");
    const [totalSales, setTotalSales] = useState("");
    const [totalPurchase, setTotalPurchase] = useState("");
    const [netLoss, setNetLoss] = useState("");
    const [netProfit, setNetProfit] = useState("");
    const [grandTotal, setGrandTotal] = useState("");

    const [emailIds, setEmailIds] = useState("");
    const [emailMessage, setEmailMessage] = useState("");

    const [buttonClicked, setButtonClicked] = useState(false);


    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });



    useEffect(() => {
        axios.get(`${config.base_url}/fetch_profit_and_loss/${ID}/`)
        .then((res) => {
          console.log(res);

          setItems(res.data.items);
          setItemsList(res.data.items_list);
          setCompanyName(res.data.company);
          setTotalOpeningStock(res.data.total_opening_stock);
          setTotalClosingStock(res.data.total_closing_stock);
          setTotalPurchaseAccounts(res.data.total_purchase_accounts);
          setTotalIndirectExpense(res.data.total_indirect_expense);
          setTotalExpense(res.data.total_expense);
          setTotalDiscountPaid(res.data.total_discount_paid);
          setTotalSalesAccounts(res.data.total_sales_accounts);
          setTotalDiscountReceived(res.data.total_discount_received);
          setTotalSales(res.data.total_sales);
          setTotalPurchase(res.data.total_purchase);
          setNetLoss(res.data.net_loss);
          setNetProfit(res.data.net_profit);
          setGrandTotal(res.data.grand_total);
   
        })
        .catch((err) => {
          console.log("ERROR=", err);
          // setIsLoading(false); // Ensure loading is set to false even on error
        });

        setButtonClicked(false);
    
      }, [buttonClicked]);



    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
          contentDiv.style.display = "none";
        } else {
          contentDiv.style.display = "block";
          
        }
      }

      
      const [showFirstColumns, setShowFirstColumns] = useState(false);
      const [showPurchase, setShowPurchase] = useState(false);
      const [showExpenses, setShowExpenses] = useState(false);
      const [showSalesAccounts, setShowSalesAccounts] = useState(false);
      const [showIndirectIncomes, setShowIndirectIncomes] = useState(false);
      const [showClosingStock, setShowClosingStock] = useState(false);

    
      const toggleFirstColumns = () => {
        setShowFirstColumns((prev) => !prev);
      };

      const togglePurchase = () => {
        setShowPurchase((prev) => !prev);
      }

      const toggleExpenses = () => {
        setShowExpenses((prev) => !prev);
      }

      const toggleSalesAccounts = () => {
        setShowSalesAccounts((prev) => !prev);
      }

      const toggleIndirectIncomes = () => {
        setShowIndirectIncomes((prev) => !prev);
      }

      const toggleClosingStock = () => {
        setShowClosingStock((prev) => !prev);
      }




    const fetchprofitandlossbydate = (e) => {
      e.preventDefault();
  
      toggleContent();
  
      console.log("fetching profit and loss")
  
      console.log(ID)
      console.log(startDate)
      console.log(endDate)
  
      var params  = {
        id: ID,
        start_date: startDate,
        end_date: endDate,
      };
  
      axios
        .get(`${config.base_url}/fetch_profit_and_loss_by_date/`, { params })
        .then((res) => {
          console.log("REPRT DATA=", res);

          setItems(res.data.items);
          setItemsList(res.data.items_list);
          setCompanyName(res.data.company);
          setTotalOpeningStock(res.data.total_opening_stock);
          setTotalClosingStock(res.data.total_closing_stock);
          setTotalPurchaseAccounts(res.data.total_purchase_accounts);
          setTotalIndirectExpense(res.data.total_indirect_expense);
          setTotalExpense(res.data.total_expense);
          setTotalDiscountPaid(res.data.total_discount_paid);
          setTotalSalesAccounts(res.data.total_sales_accounts);
          setTotalDiscountReceived(res.data.total_discount_received);
          setTotalSales(res.data.total_sales);
          setTotalPurchase(res.data.total_purchase);
          setNetLoss(res.data.net_loss);
          setNetProfit(res.data.net_profit);
          setGrandTotal(res.data.grand_total);

  
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
      
  
  
    }


    function reportPDF() {
      var st = startDate;
      var en = endDate;
      var date = "";
      if (st != "" && en != "") {
        date = `_${startDate}` + "_" + `${endDate}`;
      }
      var element = document.getElementById("printReport");
      var opt = {
        margin: [0.5, 0.3, 0.3, 0.5],
        filename: "Profit_And_Loss_Report" + date,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
      };
      html2pdf().set(opt).from(element).save();
    } 
  
  
  
    function ExportToExcel() {
      var st = startDate;
      var en = endDate;
      var date = "";
      if (st != "" && en != "") {
        date = `_${startDate}` + "_" + `${endDate}`;
      }
    
      // Get both tables
      const table1 = document.getElementById("reportTable1");
      const table2 = document.getElementById("reportTable2");
    
      // Convert the first table to a worksheet
      const ws1 = XLSX.utils.table_to_sheet(table1);
    
      // Convert the second table to JSON without headers
      const table2Data = XLSX.utils.sheet_to_json(XLSX.utils.table_to_sheet(table2), { header: 1 });
    
      // Determine the number of columns in the first worksheet
      const ws1ColCount = XLSX.utils.decode_range(ws1['!ref']).e.c + 1;
    
      // Add the second table's data to the first worksheet, starting at the next available column
      XLSX.utils.sheet_add_json(ws1, table2Data, { skipHeader: true, origin: { r: 0, c: ws1ColCount } });
    
      // Create a new workbook and append the combined worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
    
      // Export the workbook to an Excel file
      XLSX.writeFile(wb, "Profit_And_Loss_Report" + date + ".xlsx");
    
    



      
    }
    

  
  
    function printSection() {
      var divToPrint = document.getElementById("printReport");
      var printWindow = window.open("", "", "height=700,width=1000");
  
      printWindow.document.write("<html><head><title></title>");
      printWindow.document.write(`
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
      `);
      printWindow.document.write("</head>");
      printWindow.document.write("<style>");
      printWindow.document.write(`

      
       body {
      margin: 0;
      padding: 0;
    }

    .table-container {
      display: flex;
      justify-content: flex-start; /* Align tables to the left */
      align-items: stretch;
      width: 70%;
      margin: 0;
      padding: 0;
    }

    .table-container table {
      margin: 0;
      padding: 0;
      border-spacing: 0;
      border-collapse: collapse;
      width: 50%; /* Adjust as needed to ensure both tables fit */
      border: 0;
    }

    .table-container table:first-child {
      margin-right: 0; /* No margin between the two tables */
    }

    #reportTable1 th, #reportTable1 td,
    #reportTable2 th, #reportTable2 td {
      border: 1px solid #dddddd;
      padding: 5px 2px;
      color: #000;
    }

    .collapsed-row {
      opacity: 0.5;
    }
    `);
      printWindow.document.write("</style>");
      printWindow.document.write("<body>");
      printWindow.document.write(divToPrint.outerHTML);
      printWindow.document.write("</body>");
      printWindow.document.write("</html>");
      printWindow.document.close();
      printWindow.print();
      printWindow.addEventListener("afterprint", function () {
      printWindow.close();
      });
    }

    const currentUrl = window.location.href;
    const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      currentUrl
    )}`;




function handleShareEmail(e) {
  e.preventDefault();

  var st = startDate;
  var end = endDate;
  var itms = items;
  var items_list = itemsList;
  var company_name = companyName;
  var total_opening_stock = totalOpeningStock;
  var total_closing_stock = totalClosingStock;
  var total_purchase_accounts = totalPurchaseAccounts;
  var total_indirect_expense = totalIndirectExpense;
  var total_expense = totalExpense;
  var total_discount_paid = totalDiscountPaid;
  var total_sales_accounts = totalSalesAccounts;
  var total_discount_received = totalDiscountReceived;
  var total_sales = totalSales;
  var total_purchase = totalPurchase;
  var net_loss = netLoss;
  var net_profit = netProfit;
  var grand_total = grandTotal;
  


  if ((st != "" && end == "") || (st == "" && end != "")) {
    alert("Please select both date inputs or select none");
    return;
  } else {
    var emailsString = emailIds.trim();

    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];

        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }

      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        // document.getElementById("share_to_email_form").submit();
        var em = {
          Id: ID,
          start: st,
          end: end,
          email_ids: emailIds,
          email_message: emailMessage,
          items: itms,
          items_list: items_list,
          total_opening_stock: total_opening_stock,
          total_closing_stock: total_closing_stock,
          total_purchase_accounts: total_purchase_accounts,
          total_indirect_expense: total_indirect_expense,
          total_expense: total_expense,
          total_discount_paid: total_discount_paid,
          total_sales_accounts: total_sales_accounts,
          total_discount_received: total_discount_received,
          total_sales: total_sales,
          total_purchase: total_purchase,
          net_loss: net_loss,
          net_profit: net_profit,
          grand_total: grand_total,

        };
        axios
          .post(`${config.base_url}/share_horizontal_profit_and_loss_email/`, em)
          .then((res) => {
            if (res.data.status) {


              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (
              err.response &&
              err.response.data &&
              !err.response.data.status
            ) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }
}




    const handleClick = () => {
      setButtonClicked(true);
      setStartDate("");
      setEndDate("");
    };





  return (
    <>
        <FinBase />
        <div
            className="page-content mt-0 pt-0"
            id="page-content"
            style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
        >

<div className="card radius-15">
  <div className="card-body" style={{ width: "100%" }}>
    <div className="card-title">
      <center>
        <h2 className="text-uppercase" id="headline">
          HORIZONTAL PROFIT AND LOSS A/C
        </h2>
      </center>
      <hr />
    </div>
    <div className="bar row">
      <div className="col-12 col-md-6 left d-flex justify-content-start mb-3">
        <div className="position-relative mr-2">
          <button
            className="btn btn-secondary"
            onClick={toggleContent}
            style={{ width: "fit-content", height: "fit-content" }}
          >
            <i className="fas fa-solid fa-gear"></i> Customize Report
          </button>
          <div id="contentDiv" className="salescontent">
            <h6>Customize Report</h6>
            <form
              onSubmit={fetchprofitandlossbydate}
              className="form reportCustomizeForm px-1"
              method="get"
            >
              <div className="px-2 w-100">
                <label style={{ textAlign: "left" }}>From</label>
                <br />
                <input
                  className="inputdate form-control"
                  type="date"
                  name="start_date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </div>
              <div className="px-2 w-100">
                <label style={{ textAlign: "left" }}>To</label>
                <br />
                <input
                  type="date"
                  className="inputdate form-control"
                  name="end_date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex px-2 mt-3 mb-4 w-100">
                <button
                  type="submit"
                  className="btn btn-outline-light w-50"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Run Report
                </button>
                <button
                  type="reset"
                  onClick={toggleContent}
                  className="btn btn-outline-light ml-1 w-50"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 right d-flex flex-wrap justify-content-end">
        <a
          className="btn btn-outline-secondary text-grey fa fa-file mb-2 mr-md-2"
          role="button"
          id="pdfBtn"
          onClick={reportPDF}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;PDF
        </a>
        <a
          className="btn btn-outline-secondary text-grey fa fa-print mb-2 mr-md-2"
          role="button"
          id="printBtn"
          onClick={printSection}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Print
        </a>
        <a
          className="btn btn-outline-secondary text-grey fa fa-table mb-2 mr-md-2"
          role="button"
          id="exportBtn"
          onClick={ExportToExcel}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Export
        </a>
        <div className="dropdown p-0 nav-item mb-2 mr-md-2" id="shareBtn">
          <li
            className="dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
            data-toggle="dropdown"
            style={{
              height: "fit-content",
              width: "fit-content",
            }}
          >
            &nbsp;Share
          </li>
          <ul
            className="dropdown-menu"
            style={{ backgroundColor: "black" }}
            id="listdiv"
          >
            <a href={shareUrl} target="_blank" rel="noopener noreferrer">
              <li
                style={{
                  textAlign: "center",
                  color: "#e5e9ec",
                  cursor: "pointer",
                }}
              >
                WhatsApp
              </li>
            </a>
            <li
              style={{
                textAlign: "center",
                color: "#e5e9ec",
                cursor: "pointer",
              }}
              data-toggle="modal"
              data-target="#shareToEmail"
            >
              Email
            </li>
          </ul>
        </div>
        <a
          className="btn btn-outline-secondary text-grey fa fa-arrow-left mb-2 mr-md-2"
          role="button"
          id="backBtn"
          onClick={handleClick}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Back
        </a>
      </div>
    </div>
  </div>
</div>




            <div className="card radius-15 print-only" id="pdf-card">
                <div className="card-body">
                    <div className="container-fluid">
                    <div
                        id="printReport"
                        className="printReportTemplate"
                        style={{ display: "block " }}
                    >
                        <div className="my-5 page" size="A4">
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary">
                            <div className="col-12">
                                <center className="h5 text-white">
                                <b>{companyName}</b>
                                </center>
                                <center className="h3 text-white">
                                <b> HORIZONTAL PROFIT AND LOSS A/C</b>
                                </center>
                                {startDate != "" && endDate != "" ? (
                                <center className="h6 text-white">
                                    {startDate} {"TO"} {endDate}
                                </center>
                                ) : null}
                            </div>
                            </div>
                            <div className="row px-1 py-1">
                            <div className="col-12">

                            <section className="product-area mt-2 py-1">
                                <div className="row table-container">
                                  <div className="table-container">
                                    <table className="table table-responsive-md mt-4 table-hover" id="reportTable1">
                                      <thead>
                                        <tr>
                                          <th>PARTICULAR</th>
                                          <th>AMOUNT</th>
                                          <th>AMOUNT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td onClick={toggleFirstColumns}>OPENING STOCK</td>
                                          <td></td>
                                          <td>
                                            {typeof totalOpeningStock === 'number' 
                                            ? totalOpeningStock.toFixed(2) 
                                            : parseFloat(totalOpeningStock)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>
                                        {showFirstColumns &&
                                          itemsList.map((itm) => (
                                            <tr key={itm[0]} class="collapsed-row">
                                              <td>{itm[1]}</td>
                                              <td>{itm[2]}</td>
                                              <td></td>
                                            </tr>
                                          ))}
                                        <tr>
                                          <td onClick={togglePurchase}>PURCHASE ACCOUNTS</td>
                                          <td></td>
                                          <td>
                                            {typeof totalPurchaseAccounts === 'number' 
                                            ? totalPurchaseAccounts.toFixed(2) 
                                            : parseFloat(totalPurchaseAccounts)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>
                                        {showPurchase && (
                                          <>
                                            <tr class="collapsed-row">
                                              <td>PURCHASE</td>
                                              <td>
                                                {typeof totalPurchaseAccounts === 'number' 
                                                ? totalPurchaseAccounts.toFixed(2) 
                                                : parseFloat(totalPurchaseAccounts)?.toFixed(2) || '0.00'}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr class="collapsed-row">
                                              <td>PURCHASE BILL TO COME</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </>
                                        )}
                                        <tr>
                                          <td onClick={toggleExpenses}>INDIRECT EXPENSES</td>
                                          <td></td>
                                          <td>
                                            {typeof totalIndirectExpense === 'number' 
                                            ? totalIndirectExpense.toFixed(2) 
                                            : parseFloat(totalIndirectExpense)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>
                                        {showExpenses && (
                                          <>
                                            <tr class="collapsed-row">
                                              <td>EXPENSES</td>
                                              <td>
                                              {typeof totalExpense === 'number' 
                                            ? totalExpense.toFixed(2) 
                                            : parseFloat(totalExpense)?.toFixed(2) || '0.00'}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr class="collapsed-row">
                                              <td>DISCOUNT PAID</td>
                                              <td>
                                                {typeof totalDiscountPaid === 'number' 
                                                ? totalDiscountPaid.toFixed(2) 
                                                : parseFloat(totalDiscountPaid)?.toFixed(2) || '0.00'}
                                              </td>
                                              <td></td>
                                            </tr>
                                          </>
                                        )}
                                        {netProfit === false ? (
                                          <tr>
                                          <td>-</td>
                                          <td>-</td>
                                          <td>-</td>
                                        </tr>
                                        ) :(
                                          <tr>
                                            <td>NET PROFIT</td>
                                            <td></td>
                                            <td>
                                              {typeof netProfit === 'number' 
                                              ? netProfit.toFixed(2) 
                                              : parseFloat(netProfit)?.toFixed(2) || '0.00'}
                                              </td>
                                          </tr>
                                        )}
                                        <tr>
                                          <td><b>Total</b></td>
                                          <td></td>
                                          <td><b>
                                            {typeof grandTotal === 'number' 
                                            ? grandTotal.toFixed(2) 
                                            : parseFloat(grandTotal)?.toFixed(2) || '0.00'}</b></td>
                                        </tr>
                                      </tbody>
                                    </table>



                                    <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                                      <thead>
                                        <tr>
                                          <th>PARTICULAR</th>
                                          <th>AMOUNT</th>
                                          <th>AMOUNT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td onClick={toggleSalesAccounts}>SALES ACCOUNT</td>
                                          <td></td>
                                          <td>
                                            {typeof totalSalesAccounts === 'number' 
                                            ? totalSalesAccounts.toFixed(2) 
                                            : parseFloat(totalSalesAccounts)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>

                                        {showSalesAccounts && (
                                          <>
                                            <tr class="collapsed-row">
                                              <td>SALES</td>
                                              <td>
                                                {typeof totalSalesAccounts === 'number' 
                                                ? totalSalesAccounts.toFixed(2) 
                                                : parseFloat(totalSalesAccounts)?.toFixed(2) || '0.00'}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr class="collapsed-row">

                                              <td>SALES BILL TO COME</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </>
                                        )}


                                        <tr>
                                          <td onClick={toggleIndirectIncomes}>INDIRECT INCOMES</td>
                                          <td></td>
                                          <td>
                                            {typeof totalDiscountReceived === 'number' 
                                            ? totalDiscountReceived.toFixed(2) 
                                            : parseFloat(totalDiscountReceived)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>

                                        {showIndirectIncomes && (
                                          <>
                                            <tr class="collapsed-row">
                                              <td>DISCOUNT RECEIVED</td>
                                              <td>
                                                {typeof totalDiscountReceived === 'number' 
                                                ? totalDiscountReceived.toFixed(2) 
                                                : parseFloat(totalDiscountReceived)?.toFixed(2) || '0.00'}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr class="collapsed-row">
                                              <td>INTEREST RECEIVED</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr class="collapsed-row">
                                              <td>LOADING UNLOADING</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </>
                                        )}

                                        <tr>
                                          <td onClick={toggleClosingStock}>CLOSING STOCK</td>
                                          <td></td>
                                          <td>
                                            {typeof totalClosingStock === 'number' 
                                            ? totalClosingStock.toFixed(2) 
                                            : parseFloat(totalClosingStock)?.toFixed(2) || '0.00'}
                                          </td>
                                        </tr>
                                        {showClosingStock &&
                                          itemsList.map((itm) => (
                                            itm[3] !== 0 && (
                                              <tr key={itm[0]} className="collapsed-row">
                                                <td>{itm[1]}</td>
                                                <td>{itm[3]}</td>
                                                <td></td>
                                              </tr>
                                            )
                                          ))
                                        }
                                        {netLoss === false ? (
                                          <tr>
                                          <td>-</td>
                                          <td>-</td>
                                          <td>-</td>
                                        </tr>
                                          
                                        ) :(
                                          <tr>
                                            <td>NET LOSS</td>
                                            <td></td>
                                            <td>
                                              {typeof netLoss === 'number' 
                                              ? netLoss.toFixed(2) 
                                              : parseFloat(netLoss)?.toFixed(2) || '0.00'}
                                            </td>
                                          </tr>
                                        )}
                                        <tr>
                                          <td><b>Total</b></td>
                                          <td></td>
                                          <td><b>
                                            {typeof grandTotal === 'number' 
                                            ? grandTotal.toFixed(2) 
                                            : parseFloat(grandTotal)?.toFixed(2) || '0.00'}
                                            </b></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </section>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>



        {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    
    </>
  )
}

export default HorizontalProfitAndLoss