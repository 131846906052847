import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import html2pdf from "html2pdf.js";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/PurchaseOrderItemReport.css";

function CustomerBalanceReport() {
    const ID = Cookies.get('Login_id');
    const [payment,setPayment] = useState([]);
    const [comp,setCompany] = useState([]);
    const [totpay,setTotpay] = useState([]);
    const [totamount,setTotamount] = useState([]);
    const [totbal,setTotbal] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customer, setCustomer] = useState([]);
    const [selectedCustomer,setSelectedCustomer] = useState('')
    const [status, setStatus] = useState("");
    const [data, setData] = useState({ customers: [] });
    const [totcus,setTotCus] = useState([]);
    const [totinvoice,setTotInvoice] = useState([]);
    const [totrecinvoice,setTotRecInvoice] = useState([]);
    const [totcreditenote,setTotCrediteNote] = useState([]);
    const [totsale,setTotSale] = useState([]);
    const [totsubtotal,setTotSubTotal] = useState([]);

    const [billsChecked, setBillsChecked] = useState(false);
    const [dnoteChecked, setDnoteChecked] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

   

    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
        } else {
            contentDiv.style.display = "block";
        }
    }

    const fetchPayments = () =>{
        axios.get(`${config.base_url}/Fetch_customer_balance_data/${ID}/`).then((res)=>{
            if(res.data.status){
            //   var pay = res.data.payment;
            //   var comp = res.data.company;
            //   var totpay = res.data.totpay;
            //   var totamount = res.data.totamount;
              // var totcus = res.data.totbal;
              console.log('ciss data=',res.data.customers)
              if (res.data.customers.length > 0) {
                setData({ customers: res.data.customers || [] }); 
            } else {
                setData(null); 
            }
              
              setTotCus(res.data.totalCustomers);
              setTotInvoice(res.data.totalInvoice);
              setTotRecInvoice(res.data.totalRecInvoice);
              setTotCrediteNote(res.data.totalCreditNote)
              setTotSale(res.data.total_balance1)
              setTotSubTotal(res.data.total_invoice_balance)
              setTotamount(res.data.totamount);
              setTotbal(res.data.totbal);
              setStartDate("");
              setEndDate("");
              setStatus("");
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
    };


    const fetchCustomer = () =>{
        axios.get(`${config.base_url}/fetch_customer_balance/${ID}/`).then((res)=>{
            if(res.data.status){
            //   var pay = res.data.payment;
            //   var comp = res.data.company;
            //   var totpay = res.data.totpay;
            //   var totamount = res.data.totamount;
            //   var totbal = res.data.totbal;
              console.log('customer=',res.data.customer)
              
              setCustomer(res.data.customer);
              setCompany(res.data.comp)
              console.log('customer comapny=',customer.company )
              
             
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
    };



    useEffect(()=>{
        fetchPayments();
        fetchCustomer();
      },[])
      function reportPDF() {
        var st = startDate;
        console.log(st)
        var en = endDate;
        var date = "";
        if (st != "" && en != "") {
          date = `_${startDate}` + "_" + `${endDate}`;
        }
        var element = document.getElementById("printReport");
        var opt = {
          margin: [0.5, 0.3, 0.3, 0.5],
          filename: "Customer_Balance_Details" + date,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
      }
    function printSheet() {
        var divToPrint = document.getElementById("printReport");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }

      function handleCheckChange(){
        if (billsChecked || dnoteChecked) {
            setShowWarning(false);
        } 
      }

      function ExportToExcel() {
        var st = startDate;
        var en = endDate;
        var date = "";
        if (st != "" && en != "") {
          date = `_${startDate}` + "_" + `${endDate}`;
        }
        const Table = document.getElementById("reportTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Customer_Balance_Details" + date + ".xlsx");
      }
    const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;
  const handleCustomize = (e) => {
    e.preventDefault();

    
      

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      customer: selectedCustomer,
      invoice : billsChecked,
      credit :dnoteChecked
    };

    axios
      .post(`${config.base_url}/fetch_customer_balance_customized/`, det)
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
            //   var pay = res.data.payment;
            //   var comp = res.data.company;
            //   var totpay = res.data.totpay;
            //   var totamount = res.data.totamount;
            //   var totbal = res.data.totbal;
            if (res.data.customers.length > 0) {
                setData({ customers: res.data.customers || [] }); 
            } else {
                setData(null); 
            }
              
              setTotCus(res.data.totalCustomers);
              setTotInvoice(res.data.totalInvoice);
              setTotRecInvoice(res.data.totalRecInvoice);
              setTotCrediteNote(res.data.totalCreditNote)
              setTotSale(res.data.total_balance1)
              setTotSubTotal(res.data.total_invoice_balance)
              setTotamount(res.data.totamount);
              setTotbal(res.data.totbal);
              
             
          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
    
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var stat = status;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          var em = {
            Id: ID,
            start_date: st,
            end_date: end,
            customer: selectedCustomer,
            invoice : billsChecked,
            credit :dnoteChecked,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(
              `${config.base_url}/fetch_customer_balance_email/`,
              em
            )
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
    return(
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
    <div className="card radius-15">
        <div className="card-body">
           
                <center>
                    <h2 className="text-uppercase"  style={{textTransform:'uppercase'}}> Customer Balance Report </h2>
                </center>
              
            
           
            <div className="bar">

                <div className="left d-flex justify-content-start">
                    <div className="position-relative mr-2">
                        <button className="btn btn-secondary" onClick={toggleContent} style={{width:'fit-content',height:'fit-content'}}><i className="fa-solid fa fa-gear"></i> Customize Report</button>
                        <div id="contentDiv" className="salescontent" style={{display:'none'}}>
                            <h6>Customize Report</h6>
                            <form onSubmit={handleCustomize} className="form reportCustomizeForm px-1" method="get">
                                <div className="px-2 w-100">
                                    <label style={{textAlign:'left'}}>From</label><br />
                                    <input name="start_date" className="inputdate form-control" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
                                </div>
                                <div className="px-2 w-100">
                                    <label style={{textAlign:'left'}}>To</label><br />
                                    <input name="end_date" type="date" className="inputdate form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
                                </div>
                                
                                <div className="px-2 w-100">
                                    <label style={{textAlign:'left'}}>Customer</label><br />
                                    <select 
                            name="status" 
                            id="status" 
                            className="form-control" 
                            value={selectedCustomer} 
                            onChange={(e) => setSelectedCustomer(e.target.value)} 
                            required
                        >
                            <option value="all">All</option> 
                            {customer.map((i) => (
                                <option 
                                    key={i.id} 
                                    value={i.id} 
                                    className="text-uppercase"
                                >
                                    {i.first_name} {i.last_name}
                                </option>
                                
                            ))}
                          
                        </select>
                                </div>
                               
                                <div className="px-2 pt-2 w-100">
                                <label style={{ textAlign: 'left' }}>Show/Hide Columns</label><br />
            {showWarning && (
                <span className="text-danger" id="no-option" style={{ fontSize: 'medium' }}>
                    Select At least One Option From Below!<br />
                </span>
            )}
            <input
                type="checkbox"
                name="bills"
                id="bills"
                
                value={billsChecked}
                
                onChange={(e) => {
                    setBillsChecked(e.target.checked);
                  
                }}
            />
            <label htmlFor="bills">Include INVOICE BALANCE</label><br />

            <input
                type="checkbox"
                name="dnote"
                id="dnote"
                value={dnoteChecked}
                
                onChange={(e) => {
                    setDnoteChecked(e.target.checked);
                    
                }}
            />
            <label htmlFor="dnote">Include AVAILABLE CREDITS</label><br />
       
                                    
                                    
                                     </div>
                               


                                


                                <div className="d-flex px-2 mt-3 mb-4 w-100">
                                    <button type="submit" className="btn btn-outline-light" id="runReportBtn" style={{width:'fit-content',height:'fit-content'}}>Run Report</button>
                                    <button type="reset" onClick={toggleContent} className="btn btn-outline-light ml-1 w-50" style={{width:'fit-content',height:'fit-content'}}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                <div className="right d-flex justify-content-end d-flex d-flex justify-content-end d-flex justify-content-end d-flex flex-column flex-lg-row">

                    <a className="btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" onClick={reportPDF} style={{width:'fit-content',height:'fit-content'}}> &nbsp;PDF</a> 
                    <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" onClick={printSheet}  style={{width:'fit-content',height:'fit-content'}}>&nbsp;Print</a>
                    <a className="ml-2 btn btn-outline-secondary text-grey fa fa-table" role="button" id="exportBtn" onClick={ExportToExcel} style={{width:'fit-content',height:'fit-content'}}>&nbsp;Export</a>
                    <div className="dropdown p-0 nav-item"  id="shareBtn">
                        <li  className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt" data-toggle="dropdown" style={{width:'fit-content',height:'fit-content'}}>&nbsp;Share</li>
                        <ul className="dropdown-menu" style={{backgroundColor:'black'}} id="listdiv">
                        <a href={shareUrl} target="_blank" rel="noopener noreferrer"><li style={{textAlign:'center'}} >Whatsapp</li></a>
                            <li style={{textAlign:'center',color:'#e5e9ec',cursor:'pointer'}} id="toggleEmailModal" data-toggle="modal" data-target="#shareToEmail">Email</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div className="card radius-15 print-only" id="pdf-card">
        <div className="card-body">
            <div className="container-fluid">

                <div  id="printReport"  className="printReportTemplate" style={{display:'block'}}>
                    <div className="my-5 page" size="A4" >
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary" >
                                <div className="col-12">
                                        <center className="h5 text-white"><b>{comp}</b></center>
                                        <center className="h3 text-white"><b>CUSTOMER BALANCE REPORT</b></center>
                                       
                                        <center>  <div id="selectedDates" className="mt-2"></div> </center>
                                       
                                </div>  
                            </div>
                            <div className="row px-1 py-1">
                                <div className="col-12">

                                    <section className="product-area mt-2">
                                        <table className="table table-responsive-md mt-4 table-hover" id="reportTable" style={{width:'100%',borderCollapse:'collapse'}}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">CUSTOMER</th>
                                                    {billsChecked && (
                            <th className="text-center">INVOICE BALANCE</th>
                            )}
                             {dnoteChecked && (
                         <th className="text-center">AVAILABLE CREDIT</th>)}
                                                    
                                                    <th className="text-center">BALANCE(FCY)</th>
                                                    <th className="text-center">BALANCE(BCY)</th>
                                                   

                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.customers && Array.isArray(data.customers) && data.customers.length > 0 ? (
                        data.customers.map((customer, index) => (
                            <tr key={index}>
                                <td className="text-center">{customer.name}</td>
                                {billsChecked && (<td>{customer.invoice_balance}</td>)}
                                {dnoteChecked && ( <td>{customer.available_credits}</td>)}
                                <td>{customer.total_balance}</td>
                                <td>{customer.total_balance}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center">No customers available</td>
                        </tr>
                    )}
                                            </tbody>
                                        </table>
                                    </section>



                                    <section className="balance-info text-dark">
  <div className="row p-3">
    <div className="col-12 col-md-10">
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-4">
          Total customers: <p style={{ fontSize: 19 }}>{totcus}</p>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-4">
          Invoice Sale: <p style={{ fontSize: 19 }}>₹{totinvoice}</p>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          Recurring Invoice Sale: <p style={{ fontSize: 19 }}>₹{totrecinvoice}</p>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          Credit Note: <p style={{ fontSize: 19 }}>₹{totcreditenote}</p>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-4">
          SubTotal: <p style={{ fontSize: 19 }}>₹{totsale}</p>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          SubTotal Without Credit Note: <p style={{ fontSize: 19 }}>₹{totsubtotal}</p>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          Total Sale:<br />(Without Credit Note) <p style={{ fontSize: 19 }}>₹{totsubtotal}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-2 text-center">
      <div style={{ paddingTop: 80, color: '#000', fontWeight: 'bold', fontSize: '1.6em' }}>Total Sale</div>
      <div className="text-dark" style={{ fontWeight: 600, fontSize: '1.5em' }} id="totvalue">
        ₹<span id="superTotal">{totsale}</span>
      </div>
    </div>
  </div>
</section>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Share To Email Modal --> */}
<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
    )
    
}
export default CustomerBalanceReport;