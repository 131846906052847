import React, { useEffect, useState } from 'react'
import FinBase from "../FinBase";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Select from 'react-select';
import "../../styles/PartyReportByItem.css";


function PartyReportByItem() {

  const ID = Cookies.get("Login_id");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [items, setItems] = useState([]);
  const [party, setParty] = useState();
  const [itemid, setItemid] = useState();
  const [partyreport, setPartyreport] = useState([]);
  const [totalSalesQty, setTotalSalesQty] = useState("");
  const [totalSalesAmt, setTotalSalesAmt] = useState("");
  const [totalPurchaseQty, setTotalPurchaseQty] = useState("");
  const [totalPurchaseAmt, setTotalPurchaseAmt] = useState("");
  const [itemName, setItemName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");



  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });



  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 14%)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightgray"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: state.isSelected ? "black" : "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
  };



  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }


  useEffect(() => {
    axios.get(`${config.base_url}/fetch_itemlist_details/${ID}/`)
    .then((res) => {

      console.log('API Response:', res.data);
      console.log('Items:', res.data.items);


      console.log(res.data);
      let items_l = res.data.items;

      const item_list = items_l.map((itm) => ({
        label: `${itm.name}`,
        value: itm.id,
      }));

      setItems(item_list);



      // setItems(res.data.items);
      setCompanyName(res.data.company);
      console.log(res.data.company);

    })
    .catch((err) => {
      console.log("ERROR=", err);
    });

  }, []);


  const fetchPartyReport = (e) => {
    e.preventDefault();

    toggleContent();

    console.log("fetching party report by item")

    console.log(ID)
    console.log(itemid)
    console.log(startDate)
    console.log(endDate)

    var params  = {
      id: ID,
      item_id: itemid,
      start_date: startDate,
      end_date: endDate,
    };

    axios
      .get(`${config.base_url}/fetch_party_report_by_item/`, { params })
      .then((res) => {
        console.log("REPRT DATA=", res);
        setPartyreport(res.data.party_report)
        setTotalSalesQty(res.data.total_sales_quantity);
        setTotalSalesAmt(res.data.total_sales_amount);
        setTotalPurchaseQty(res.data.total_purchase_quantity);
        setTotalPurchaseAmt(res.data.total_purchase_amount);
        setItemName(res.data.item_name)

        // if (res.data.status) {
        //   setReportData(res.data.reportData);
        //   setOtherDetails(res.data.otherDetails);
        //   setStartDate(res.data.startDate);
        //   setEndDate(res.data.endDate);
        //   setStatus(res.data.filterStatus);
        //   setReport(res.data.report);

        //   var contentDiv = document.getElementById("contentDiv");
        //   if (contentDiv.style.display === "block") {
        //     toggleContent();
        //   }
        // }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
    


  }



  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Party_Report_By_Item" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 



  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Party_Report_By_Item" + date + ".xlsx");
  }


  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;



  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    // var stat = status;
    var rpt = partyreport;
    var t_sales_quantity = totalSalesQty;
    var t_sales_amount = totalSalesAmt;
    var t_purchase_quantity = totalPurchaseQty;
    var t_purchase_amount = totalPurchaseAmt; 
    var item_name = itemName;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            // status: stat,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
            total_sales_quantity: t_sales_quantity,
            total_sales_amount: t_sales_amount,
            total_purchase_quantity: t_purchase_quantity,
            total_purchase_amount: t_purchase_amount,
            item_name:item_name,
          };
          axios
            .post(`${config.base_url}/share_party_report_by_item_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }


  const handleSelectChange = (selectedOption) => {
    // Check if selectedOption is not null
    if (selectedOption) {
      setItemid(selectedOption.value);
    } else {
      setItemid(""); // Handle clearing selection if needed
    }
  };






  return (
    <>
        <FinBase />
        <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >

<div className="card radius-15">
  <div className="card-body" style={{ width: "100%" }}>
    <div className="card-title">
      <center>
        <h2 className="text-uppercase" id="headline">
          PARTY REPORT BY ITEM
        </h2>
      </center>
      <hr />
    </div>
    <div className="bar row">
      <div className="left col-md-6 col-lg-4 d-flex justify-content-start mb-2">
        <div className="position-relative mr-2">
          <button
            className="btn btn-secondary"
            onClick={toggleContent}
            style={{ width: "fit-content", height: "fit-content" }}
          >
            <i className="fas fa-solid fa-gear"></i> Customize Report
          </button>
          <div id="contentDiv" className="salescontent">
            <h6>Customize Report</h6>
            <form
              onSubmit={fetchPartyReport}
              className="form reportCustomizeForm px-1"
              method="get"
            >
              <div className="px-2 w-100">
                <label style={{ textAlign: "left" }}>From</label>
                <input
                  className="inputdate form-control"
                  type="date"
                  name="start_date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </div>
              <div className="px-2 w-100">
                <label style={{ textAlign: "left" }}>To</label>
                <input
                  type="date"
                  className="inputdate form-control"
                  name="end_date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
              </div>
              <div className="px-2 w-100">
                <label style={{ textAlign: "left" }}>Item</label>
                <Select
                  options={items}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  name="entity"
                  className="w-100"
                  id="entity"
                  required
                  onChange={handleSelectChange}
                  isClearable
                  isSearchable
                />
              </div>
              <div className="d-flex px-2 mt-3 mb-4 w-100">
                <button
                  type="submit"
                  className="btn btn-outline-light w-50"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Run Report
                </button>
                <button
                  type="reset"
                  onClick={toggleContent}
                  className="btn btn-outline-light ml-1 w-50"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="right col-md-6 col-lg-8 d-flex flex-column flex-md-row align-items-md-center justify-content-md-end">
        <a
          className="btn btn-outline-secondary text-grey fa fa-file mb-2 mb-md-0 mr-md-2"
          role="button"
          id="pdfBtn"
          onClick={reportPDF}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;PDF
        </a>
        <a
          className="btn btn-outline-secondary text-grey fa fa-print mb-2 mb-md-0 mr-md-2"
          role="button"
          id="printBtn"
          onClick={printSection}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Print
        </a>
        <a
          className="btn btn-outline-secondary text-grey fa fa-table mb-2 mb-md-0 mr-md-2"
          role="button"
          id="exportBtn"
          onClick={ExportToExcel}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Export
        </a>
        <div className="dropdown p-0 nav-item" id="shareBtn">
          <li
            className="dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt mb-2 mb-md-0"
            data-toggle="dropdown"
            style={{
              height: "fit-content",
              width: "fit-content",
            }}
          >
            &nbsp;Share
          </li>
          <ul
            className="dropdown-menu"
            style={{ backgroundColor: "black" }}
            id="listdiv"
          >
            <a
              href={shareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <li
                style={{
                  textAlign: "center",
                  color: "#e5e9ec",
                  cursor: "pointer",
                }}
              >
                WhatsApp
              </li>
            </a>
            <li
              style={{
                textAlign: "center",
                color: "#e5e9ec",
                cursor: "pointer",
              }}
              data-toggle="modal"
              data-target="#shareToEmail"
            >
              Email
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>









        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{companyName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b> PARTY REPORT BY ITEM</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    <div className="row px-1 py-1">
                      <div className="col-12">

                      {/* <section className="product-area mt-2 py-1"> */}
                        {/* <div className="row p-4">
                          <div className="col-10"> */}
                            {/* <div className="row mb-2">
                              <div className="col-10">
                                <h3 className="text-dark">Item : {itemName}</h3>
                              </div>
                            </div> */}
                          {/* </div>
                        </div> */}
                        
                      {/* </section> */}

                        <section className="product-area mt-2 py-1">
                            <div className="row mb-2">
                              <div className="col-10">
                                <h4 style={{ fontSize: 19 , fontWeight: 'bold', color: 'black' }}>ITEM NAME : {itemName}</h4>
                              </div>
                            </div>
                          <table
                            className="table table-responsive-md mt-4 table-hover"
                            id="reportTable"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">PARTY NAME</th>
                                <th className="text-center">SALES QUANTITY</th>
                                <th className="text-center">SALES AMOUNT</th>
                                <th className="text-center">PURCHASE QUANTITY</th>
                                <th className="text-center">PURCHASE AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {partyreport.length > 0 &&
                                partyreport.map((report) => (
                                  <tr>
                                    <td className="text-center">{report[0]} {report[1]} {report[2]}</td>
                                    <td className="text-center">{report[3]}</td>
                                    <td className="text-center">{report[4]}</td>
                                    <td className="text-center">{report[5]}</td>
                                    <td className="text-center">{report[6]}</td>   
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          {partyreport.length == 0 ? (
                            <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                        </section>
                        
                        {partyreport.length != 0 ? (
                          <section className="balance-info text-dark">
                          <div className="row p-3">
                            <div className="col-12">
                              {/* <div className="row mb-2">
                                <div className="col-4">Total Items: <p style={{ fontSize: 19 }}></p></div>
                              </div> */}
                              <div className="row mb-2">
                                <div className="col-3">Total Sales Qty: <p id="sales" style={{ fontSize: 19 , fontWeight: 'bold' }}>{totalSalesQty}<span id="totalInvoice"></span></p></div>
                                <div className="col-3">Total Sales Amount: <p id="purchase" style={{ fontSize: 19 , fontWeight: 'bold' }}>₹{totalSalesAmt}<span id="totalReccuringinvoice"></span></p></div>
                                <div className="col-3">Total Purchase Qty: <p id="expense" style={{ fontSize: 19 , fontWeight: 'bold' }}>{totalPurchaseQty}<span id="totalCreditnote"></span></p></div>
                                <div className="col-3">Total Purchase Amount: <p id="expense" style={{ fontSize: 19 , fontWeight: 'bold' }}>₹{totalPurchaseAmt}<span id="totalCreditnote"></span></p></div>
                              </div>
                              {/* <div className="row mb-2">
                                <div className="col-4">SubTotal: <p id="sales" style={{ fontSize: 19 }}>₹<span id="totalSale"></span></p></div>
                                <div className="col-4">SubTotal Without Credit Note: <p id="expense" style={{ fontSize: 19 }}>₹<span id="totalSalewithoutCreditnotes"></span></p></div>
                                <div className="col-4">Total Sale:<br />(Without Credit Note) <p id="purchase" style={{ fontSize: 19 }}>₹<span id="grantTotal"></span></p></div>
                              </div> */}
                            </div>
                            {/* <div className="col-2 text-center">
                            <div style={{ paddingTop: 80, color: '#000', fontWeight: 'bold', fontSize: '1.6em' }}>Total Sale</div>
                              <div className="text-dark" style={{ fontWeight: 600, fontSize: '1.5em' }} id="totvalue">
                                ₹<span id="superTotal">{(otherDetails.totalSale ?? 0).toFixed(2)}</span>
                              </div>
                            </div> */}
                          </div>
                        </section>

                      ) : null}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PartyReportByItem