import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function ManualJournalHistory() {
  const ID = Cookies.get("Login_id");
  const { journalId } = useParams();
  const [history, setHistory] = useState([]);
  const [manualJournal, setManualJournal] = useState(null); // Initialize with null

  // Fetch manual journal history data from the API
  const fetchManualJournalHistory = async () => {
    try {
      const response = await axios.get(`${config.base_url}/fetch_manual_journal_history/${journalId}/`);
      console.log("API Response:", response.data); // Check the entire response
      
      if (response.data.status) {
        const { Manual_Journal, history } = response.data;
        console.log("Manual Journal Data:", Manual_Journal); // Log to confirm data
        setManualJournal(Manual_Journal || {}); // Set state with fallback to empty object
        setHistory(history || []); // Set state with fallback to empty array
      } else {
        // If status is false, clear the data
        setManualJournal(null);
        setHistory([]);
      }
    } catch (error) {
      console.log("ERROR=", error);
      const errorMessage = error.response?.data?.message || "An error occurred.";
      Swal.fire({
        icon: "error",
        title: errorMessage,
      });
    }
  };

  // Fetch data when the component mounts or journalId changes
  useEffect(() => {
    fetchManualJournalHistory();
  }, [journalId]);

  // Log state to check if manualJournal updates correctly
  useEffect(() => {
    console.log('Manual Journal State:', manualJournal);
  }, [manualJournal]);

  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to={`/view_manual_journal/${journalId}/`}
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h3
                  className="card-title"
                  style={{ textTransform: "uppercase" }}
                >
                  MANUAL JOURNAL HISTORY
                </h3>
                {manualJournal ? ( // Check if manualJournal is not null
                  manualJournal.Status === "Draft" ? (
                    <h6
                      className="blinking-text"
                      style={{ color: "red", width: "140px", fontWeight: "bold" }}
                    >
                      Draft
                    </h6>
                  ) : (
                    <h6
                      style={{
                        width: "140px",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      Saved
                    </h6>
                  )
                ) : (
                  <h6 style={{ width: "140px", color: "grey" }}>Loading...</h6> // Show a loading message if manualJournal is null
                )}
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="history">
              <center>
                <h3 className="mt-3 text-uppercase">
                  #{manualJournal?.Journal_Number || 'N/A'} - TRANSACTIONS HISTORY
                </h3>
              </center>
              <div className="table-responsive px-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">SL NO.</th>
                      <th className="text-center">DATE</th>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">DONE BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length > 0 ? (
                      history.map((h, index) => (
                        <tr key={index}> {/* Ensure each row has a unique key */}
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>{h.date}</td>
                          <td className={`text-center ${h.action === "Created" ? "text-success" : "text-warning"}`}>
                            {h.action}
                          </td>
                          <td style={{ textAlign: "center" }}>{h.name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>No history available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManualJournalHistory;
