import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";

function Banking() {
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("bankTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Banks.xlsx");
  }

  function sortTable(columnIndex) {
    setSortDropdown(false);
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("bankTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  function filterTable(row, filterValue) {
    setFilterDropdown(false);
    var table = document.getElementById("bankTable");
    var rows = table.getElementsByTagName("tr");

    for (var i = 1; i < rows.length; i++) {
      var statusCell = rows[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none";
      }
    }
  }

  function sortBalAscending() {
    setSortDropdown(false);
    var table = document.getElementById("bankTable");
    var rows = Array.from(table.rows).slice(1);

    rows.sort(function (a, b) {
      var hsnA = parseInt(a.cells[6].textContent);
      var hsnB = parseInt(b.cells[6].textContent);
      return hsnA - hsnB;
    });

    // Remove existing rows from the table
    for (var i = table.rows.length - 1; i > 0; i--) {
      table.deleteRow(i);
    }

    // Append the sorted rows back to the table
    rows.forEach(function (row) {
      table.tBodies[0].appendChild(row);
    });
  }

  function searchTable() {
    var rows = document.querySelectorAll("#bankTable tbody tr");
    var val = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    rows.forEach(function (row) {
      var text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  }

  const ID = Cookies.get("Login_id");
  const [banks, setBanks] = useState([]);

  const fetchBanks = () => {
    axios
      .get(`${config.base_url}/fetch_banks/${ID}/`)
      .then((res) => {
        console.log("BNK RES=", res);
        if (res.data.status) {
          var bnks = res.data.banks;
          setBanks([]);
          bnks.map((i) => {
            setBanks((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  function refreshAll() {
    setSortDropdown(false);
    setBanks([]);
    fetchBanks();
  }

  function formatDate(value) {
    if (value != "") {
      var date = new Date(value);
      return date.toISOString().slice(0, 10);
    } else {
      return "";
    }
  }

  const [sortDropdown, setSortDropdown] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">BANKING</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder="Search.."
                      autoComplete="off"
                      onKeyUp={searchTable}
                    />
                    <div
                      className="dropdown ml-1"
                      style={{ justifyContent: "left" }}
                    >
                      <button
                        type="button"
                        style={{ width: "fit-content", height: "fit-content" }}
                        className="btn btn-outline-secondary dropdown-toggle text-grey"
                        onClick={()=>setSortDropdown(!sortDropdown)}
                      >
                        <i className="fa fa-sort"></i> Sort by
                      </button>
                      <div
                        className={`dropdown-menu ${sortDropdown ? 'show' : ''}`}
                        style={{ backgroundColor: "black" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={refreshAll}
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                          }}
                        >
                          All
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => sortTable(2)}
                        >
                          Name
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={sortBalAscending}
                        >
                          Balance
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-12 col-md-6 d-flex flex-wrap justify-content-center justify-content-md-end">
                  <button
                    type="button"
                    style={{ width: "fit-content", height: "fit-content" }}
                    className="btn btn-outline-secondary text-grey"
                    id="exportBtn"
                    onClick={exportToExcel}
                  >
                    <i className="fa fa-table"></i> Export To Excel
                  </button>
                  <div className="dropdown ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary dropdown-toggle text-grey"
                      onClick={()=>setFilterDropdown(!filterDropdown)}
                    >
                      <i className="fa fa-filter"></i> filter by
                    </button>
                    <div
                      className={`dropdown-menu ${filterDropdown ? 'show' : ''}`}
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(7, "all")}
                      >
                        All
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(7, "active")}
                      >
                        Active
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(7, "inactive")}
                      >
                        Inactive
                      </a>
                    </div>
                  </div>
                  <Link to="/add_bank" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                    >
                      <i className="fa fa-plus font-weight-light"></i> Bank
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-responsive-md table-hover mt-4"
              id="bankTable"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>SL.NO.</th>
                  <th>DATE</th>
                  <th>BANK NAME</th>
                  <th>ACCOUNT NUMBER</th>
                  <th>IFSC CODE</th>
                  <th>OPENING BALANCE</th>
                  <th>BALANCE</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {banks &&
                  banks.map((i, index) => (
                    <tr
                      className="clickable-row"
                      onClick={() => navigate(`/view_bank/${i.id}/`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{formatDate(i.date)}</td>
                      <td>{i.bank_name}</td>
                      <td>{i.account_number}</td>
                      <td>{i.ifsc_code}</td>
                      <td>{i.opening_balance}</td>
                      <td>{i.current_balance}</td>
                      <td>{i.bank_status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banking;
