import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import config from "../../../functions/config";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import FinBase from "../FinBase";
import html2pdf from 'html2pdf.js';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

function ViewHolidays() {
    const { ID, month, year } = useParams();
    const [holidays, setHolidays] = useState([]);
    const [showCalendar, setShowCalendar] = useState(true);
    const [currentMonth, setCurrentMonth] = useState(parseInt(month));
    const [currentYear, setCurrentYear] = useState(parseInt(year));
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [emailIds, setEmailIds] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [selectedHolidayId, setSelectedHolidayId] = useState(null); // State to hold selected holiday ID

    // Ref to keep track of the current date range
    const currentDateRef = useRef({ month: parseInt(month), year: parseInt(year) });

    const fetchHolidaysData = (month, year) => {
        axios.get(`${config.base_url}/get_holidayss/${ID}/${year}/${month}/`)
            .then(response => {
                if (response.data.status) {
                    setHolidays(response.data.data);
                    // Automatically select the first holiday if available
                    if (response.data.data.length > 0) {
                        setSelectedHolidayId(response.data.data[0].Holiday_id);
                    }
                } else {
                    console.error("Error fetching holidays:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching holidays:", error);
            });
    };

    
    const fetchComments = () => {
        if (selectedHolidayId === null) {
            console.error("No holiday selected.");
            return;
        }

        axios.get(`${config.base_url}/get_comments/${selectedHolidayId}/`)
            .then(response => {
                if (response.data.status) {
                    setComments(response.data.comments);
                } else {
                    console.error("Error fetching comments:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching comments:", error);
            });
    };

    useEffect(() => {
        fetchHolidaysData(currentMonth, currentYear);
        if (selectedHolidayId !== null) {
            fetchComments();
        }
    }, [currentMonth, currentYear, selectedHolidayId, ID]);

    const events = holidays.map(holiday => {
        const startDate = new Date(holiday.Start_Date);
        const endDate = new Date(holiday.End_Date);
    
        if (!isNaN(startDate) && !isNaN(endDate)) {
            // Move end date to the next day to include the entire end date
            endDate.setDate(endDate.getDate() + 1);
    
            // Create an array of all dates from startDate to endDate
            const dateArray = [];
            for (let date = new Date(startDate); date < endDate; date.setDate(date.getDate() + 1)) {
                dateArray.push(new Date(date).toISOString().split('T')[0]);
            }
    
            return dateArray.map(date => ({
                title: holiday.Holiday_Name,
                start: date,
                end: date, // End date is the same as start date for each event
                allDay: true,
                backgroundColor: '#2f516f' // Assuming you want a specific background color
            }));
        }
        return null;
    }).flat().filter(event => event !== null);
    
    console.log("Events array:", events); // Log events array
    
    
    

    const handleViewToggle = () => {
        setShowCalendar(!showCalendar);
    };

    const printSection = () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        const printContent = document.getElementById('holidayList').outerHTML;
    
        const styles = `
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    .no-print { display: none !important; }
                }
            </style>
        `;
    
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    ${styles}
                </head>
                <body onload="window.print();window.close();">
                    ${printContent}
                </body>
            </html>
        `);
        printWindow.document.close();
    };
    
    

    const exportAsPDF = () => {
        const container = document.getElementById('holidayList');
        if (!container) {
            console.error("Container with id 'holidayList' not found.");
            return;
        }
    
        // Save the original styles
        const originalStyles = {
            color: container.style.color,
            backgroundColor: container.style.backgroundColor,
            height: container.style.height,
            padding: container.style.padding,
            margin: container.style.margin,
        };
    
        const actionColumn = container.querySelectorAll('.no-print');
        const originalActionStyles = Array.from(actionColumn).map(col => ({
            element: col,
            display: col.style.display,
            flexDirection: col.style.flexDirection,
            alignItems: col.style.alignItems,
            justifyContent: col.style.justifyContent,
            alignContent: col.style.alignContent,
            height: col.style.height,
            width: col.style.width,
            paddingLeft: col.style.paddingLeft,
        }));
    
        const actionColumnElements = container.querySelectorAll('.no-print a, .no-print .dropdown, .no-print button');
        const originalButtonStyles = Array.from(actionColumnElements).map(el => ({
            element: el,
            display: el.style.display,
            position: el.style.position,
            top: el.style.top,
            color: el.style.color,
            fontSize: el.style.fontSize,
            margin: el.style.margin,
            marginTop: el.style.marginTop,
            height: el.style.height,
            width: el.style.width,
        }));
    
        // Temporarily hide the last column
        const lastColumnIndex = container.rows[0].cells.length - 1;
        Array.from(container.rows).forEach(row => {
            row.cells[lastColumnIndex].style.display = 'none';
        });
    
        // Set text color and background color to black for PDF
        const tableStyles = {
            color: 'black',
            backgroundColor: '',
        };
    
        container.style.color = tableStyles.color;
        container.style.backgroundColor = tableStyles.backgroundColor;
    
        const headerCells = container.querySelectorAll('thead th');
        const dataCells = container.querySelectorAll('tbody td');
    
        headerCells.forEach(cell => {
            cell.style.color = tableStyles.color;
            cell.style.backgroundColor = tableStyles.backgroundColor;
        });
    
        dataCells.forEach(cell => {
            cell.style.color = tableStyles.color;
            cell.style.backgroundColor = tableStyles.backgroundColor;
        });
    
        const pdfOptions = {
            margin: [10, 10, 10, 10],
            filename: `Holidays_${currentMonth}_${currentYear}.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
    
        html2pdf().from(container).set(pdfOptions).save().then(() => {
            setTimeout(() => {
                Array.from(container.rows).forEach(row => {
                    row.cells[lastColumnIndex].style.display = '';
                });
    
                container.style.color = originalStyles.color;
                container.style.backgroundColor = originalStyles.backgroundColor;
    
                headerCells.forEach(cell => {
                    cell.style.color = originalStyles.color;
                    cell.style.backgroundColor = '';
                });
    
                dataCells.forEach(cell => {
                    cell.style.color = originalStyles.color;
                    cell.style.backgroundColor = '';
                    cell.style.height = originalStyles.height;
                    cell.style.padding = originalStyles.padding;
                });
    
                originalActionStyles.forEach(style => {
                    style.element.style.display = style.display;
                    style.element.style.flexDirection = style.flexDirection;
                    style.element.style.alignItems = style.alignItems;
                    style.element.style.justifyContent = style.justifyContent;
                    style.element.style.alignContent = style.alignContent;
                    style.element.style.height = style.height;
                    style.element.style.width = style.width;
                    style.element.style.paddingLeft = style.paddingLeft;
                });
    
                originalButtonStyles.forEach(style => {
                    style.element.style.display = style.display;
                    style.element.style.position = style.position;
                    style.element.style.top = style.top;
                    style.element.style.color = style.color;
                    style.element.style.fontSize = style.fontSize;
                    style.element.style.margin = style.margin;
                    style.element.style.marginTop = style.marginTop;
                    style.element.style.height = style.height;
                    style.element.style.width = style.width;
                });
    
            }, 0);
        }).catch(error => {
            console.error("Error generating PDF:", error);
        });
    };
    


    const handleDelete = (holidayId) => {
        if (!window.confirm("Are you sure you want to delete this holiday?")) {
            return;
        }
    
        // Optimistically remove the holiday from the state
        const updatedHolidays = holidays.filter(holiday => holiday.Holiday_id !== holidayId);
        setHolidays(updatedHolidays);
    
        axios.delete(`${config.base_url}/delete_holiday/${holidayId}/`)
            .then(response => {
                if (response.data.status) {
                    alert('Holiday deleted successfully.');
                } else {
                    // Rollback if the deletion failed
                    setHolidays(holidays);  // Restore previous state
                    alert('Failed to delete holiday: ' + response.data.message);
                }
            })
            .catch(error => {
                // Rollback if the request failed
                setHolidays(holidays);  // Restore previous state
                console.error("Error deleting holiday:", error);
                alert('An error occurred while deleting the holiday.');
            });
    };

    const handleDatesSet = (arg) => {
        const newMonth = arg.view.currentStart.getMonth() + 1;
        const newYear = arg.view.currentStart.getFullYear();
        if (currentDateRef.current.month !== newMonth || currentDateRef.current.year !== newYear) {
            setCurrentMonth(newMonth);
            setCurrentYear(newYear);
            currentDateRef.current = { month: newMonth, year: newYear };
        }
    };

    

    const handleShareHolidayEmail = (e) => {
        e.preventDefault();
    
        // Validate email IDs
        if (!emailIds) {
            toast.error("Please provide email IDs and a message.");
            return;
        }
    
        // Prepare email data
        const emailData = {
            emailIds: emailIds.split(',').map(email => email.trim()), // Convert comma-separated string to array
            message: emailMessage,
            holidays: holidays.map(holiday => ({
                name: holiday.Holiday_Name,
                startDate: holiday.Start_Date,
                endDate: holiday.End_Date
            }))
        };
    
        // Make API call to send email
        axios.post(`${config.base_url}/share_holidays_email/`, emailData)
            .then(response => {
                console.log(response.data)
                if (response.data.status) {
                    toast.success("Email sent successfully!");
                    setEmailIds('');
                    setEmailMessage('');
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    });
                    // Optionally, you could also close the modal here
                    // document.getElementById('shareEmailModal').modal('hide');
                } else {
                    Swal.fire({
                        icon: "error",
                        text: response.data.message,
                      });
                }
            })
            .catch(error => {
                toast.error("Error sending email.");
                console.error("Error sending email:", error);
            });
    };

    const saveComment = (e) => {
        e.preventDefault();
        if (selectedHolidayId === null) {
            toast.error("No holiday selected.");
            return;
        }

        const cmt = {
            ID: ID, // This should be the company or user ID
            holiday_id: selectedHolidayId, // This should be the holiday ID
            comments: comment,
        };

        axios.post(`${config.base_url}/save_holiday_comment/`, cmt)
            .then(response => {
                if (response.data.status) {
                    toast.success("Comment added successfully!");
                    setComment('');
                    fetchComments();
                } else {
                    toast.error("Failed to add comment.");
                }
            })
            .catch(error => {
                toast.error("Error adding comment.");
                console.error("Error adding comment:", error);
            });
    };

    const deleteComment = (commentId) => {
        axios.delete(`${config.base_url}/delete_comment/${commentId}/`)
            .then(response => {
                if (response.data.status) {
                    toast.success("Comment deleted successfully!");
                    fetchComments();
                } else {
                    toast.error("Failed to delete comment.");
                }
            })
            .catch(error => {
                toast.error("Error deleting comment.");
                console.error("Error deleting comment:", error);
            });
    };

    const currentUrl = window.location.href;
    const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`;

    const calendarContainerStyle = {
        backgroundColor: 'rgba(33, 66, 92  )',
        padding: '20px', // Optional: Add padding if needed
        borderRadius: '10px' // Optional: Add border-radius if needed // Replace with your desired background color
        
    };

    return (
        <>
            <FinBase />
            <div style={{ backgroundColor: '#2f516f', minHeight: "100vh",padding: "20px" }}>
                <div className="row radius-15">
                    <div  id="calendarDiv" className="col">
                        <center>
                            <h3>
                                <a role="button" id="calendarLink"
                                    onClick={handleViewToggle}
                                    style={{
                                        padding: '10px 10%',
                                        borderTopRightRadius: '15px',
                                        borderTopLeftRadius: '15px',
                                        height: "fit-content",
                                        width: "fit-content",
                                        backgroundColor:'rgba(33, 66, 92  )'
                                    }}>Calendar</a>
                            </h3>
                        </center>
                    </div>
                    <div id="holidayListDiv" className="col">
                        <center>
                            <h3>
                                <a role="button" id="holidayListLink"
                                    onClick={handleViewToggle}
                                    style={{
                                        padding: '10px 10%',
                                        borderTopRightRadius: '15px',
                                        borderTopLeftRadius: '15px',
                                        height: "fit-content",
                                        width: "fit-content",
                                        // backgroundColor: !showCalendar ? 'rgba(33, 66, 92)' : 'initial'
                                    }}>Holiday List</a>
                            </h3>
                        </center>
                    </div>
                </div>

                {showCalendar ? (
                    <div style={calendarContainerStyle}>
                    <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth" height={600}
                    initialDate={`${currentYear}-${String(currentMonth).padStart(2, '0')}-01`}
                    events={events}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: ''
                    }}
                    datesSet={handleDatesSet}
                    eventSourceSuccess={data => {
                        console.log('Events successfully loaded:', data);
                    }}
                />
                </div>
                
                ) : (
                    <div className="card card-registration card-registration-2" style={{ borderRadius: '15px', padding: '50px' }}>
                        <div className="card-body p-0">
                            <div className="row g-0 mx-0">
                                <div className="pb-3 px-2" style={{ width: '100%' }}>
                                    <div className="card-body">
                                        <div className="card-title">
                                            <div className="row">
                                                <div className="col mt-3">
                                                    <h2 className="mb-0">{`Holidays List`}</h2>
                                                </div>
                                                <div className="col-md-6 d-flex flex-wrap justify-content-end">
                                                    <a href="#" className="ml-2 btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" onClick={exportAsPDF} title="Download PDF"style={{ width: '100px', height: '30px' }}> &nbsp;PDF</a>
                                                    <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" onClick={printSection} title="Print"style={{ width: '100px', height: '30px' }}>&nbsp;Print</a>
                                                    <div className="dropdown p-0 nav-item">
                                                        <li className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt" data-toggle="dropdown"style={{ width: '100px', height: '30px' }}>&nbsp;Share</li>
                                                        <ul className="dropdown-menu" style={{ backgroundColor: 'black' }}>
                                                            <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                                                <li style={{ textAlign: 'center', color: '#e5e9ec', cursor: 'pointer' }}>Whatsapp</li>
                                                            </a>
                                                            <li style={{ textAlign: 'center', color: '#e5e9ec', cursor: 'pointer' }} data-toggle="modal" data-target="#shareEmailModal">Email</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <table className="table table-responsive-md" id="holidayList">
                                            <thead className="thead">
                                                <tr style={{ fontSize: 'large' }}>
                                                    <th>Sl No.</th>
                                                    <th>Holiday Name</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th className="no-print">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {holidays.map((holiday, index) => (
                                                    <tr style={{ fontSize: 'large' }} key={index}>
                                                        <td style={{ width: '50px' }}>{index + 1}</td>
                                                        <td>{holiday.Holiday_Name}</td>
                                                        <td>{holiday.Start_Date}</td>
                                                        <td>{holiday.End_Date}</td>
                                                        <td className="no-print" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                                            {/* Delete Icon */}
                                                            <a role="button" onClick={() => handleDelete(holiday.Holiday_id)}>
                                                                <i className="fa fa-solid fa-trash" style={{ color: 'crimson', fontSize: '22px',marginTop:'5px' }}></i>
                                                            </a>
                                                            {/* Edit Icon */}
                                                            <Link to={`/edit_holidays/${holiday.Holiday_id}/?ID=${ID}`} id="editBtn" role="button">
                                                                <i className="fa fa-solid fas fa-edit" style={{ color: 'cyan', fontSize: '20px',marginTop:'10px' }}></i>
                                                            </Link>
                                                            {/* Dropdown */}
                                                            <div className="dropdown">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-grey"
                                                                    data-toggle="dropdown"
                                                                    style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop:'15px' }}>
                                                                    <i className="fa fa-solid fa-ellipsis-v"></i>
                                                                </button>
                                                                <div className="dropdown-menu" style={{ backgroundColor: 'black', borderRadius: '2px' }}>
                                                                    <Link to={`/holiday_history/${holiday.Holiday_id}/`} id="historyBtn" role="button" style={{ fontSize: '15px',paddingLeft:'25px' }}>
                                                                        History
                                                                    </Link>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                                                        data-toggle="modal"
                                                                        data-target="#commentModal"
                                                                        onClick={() => setSelectedHolidayId(holiday.Holiday_id)}>
                                                                        Comments
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Comment Modal */}
            <div
      className="modal fade"
      id="commentModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="commentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
          <div className="modal-header">
            <h3 className="modal-title" id="commentModalLabel">
              Add Comments
            </h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form onSubmit={saveComment} className="px-1">
            <div className="modal-body w-100">
              <textarea
                type="text"
                className="form-control"
                name="comment"
                value={comment}
                required
                onChange={(e) => setComment(e.target.value)}
              />
              {comments.length > 0 ? (
                <div className="container-fluid">
                  <table className="table mt-4">
                    <thead>
                      <tr>
                        <th className="text-center">sl no.</th>
                        <th className="text-center">Comment</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {comments.map((c, index) => (
                        <tr className="table-row" key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{c.Comments}</td>
                          <td className="text-center">
                            <a
                              className="text-danger"
                              onClick={() => deleteComment(c.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa fa-trash"
                                style={{
                                  fontSize: "1.1rem",
                                }}
                              ></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="my-2 font-weight-bold d-flex justify-content-center">
                  No Comments.!
                </span>
              )}
            </div>

            <div className="modal-footer w-100">
              <button
                type="button"
                style={{ width: "fit-content", height: "fit-content" }}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                style={{ width: "fit-content", height: "fit-content" }}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
            {/* <!-- Share To Email Modal --> */}
            <div className="modal fade" id="shareEmailModal" tabIndex="-1" role="dialog" aria-labelledby="shareEmailModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg">
        <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
                <h5 className="m-3">Share Holiday Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleShareHolidayEmail} className="needs-validation px-1" id="share_to_email_form">
                    <div className="card p-3 w-100">
                        <div className="form-group">
                            <label htmlFor="emailIds">Email IDs (comma separated)</label>
                            <textarea
                                className="form-control"
                                id="emailIds"
                                rows="3"
                                placeholder="Multiple emails can be added by separating with a comma(,)."
                                value={emailIds}
                                onChange={(e) => setEmailIds(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="emailMessage">Message (optional)</label>
                            <textarea
                                className="form-control"
                                id="emailMessage"
                                rows="4"
                                placeholder="This message will be sent along with holiday details."
                                value={emailMessage}
                                onChange={(e) => setEmailMessage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center w-100" style={{ borderTop: "1px solid #ffffff" }}>
                        <button type="submit" className="submitShareEmailBtn w-50 text-uppercase">
                            SEND MAIL
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

        </>
    );
}

export default ViewHolidays;
