import React, { useState, useEffect, useRef } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function Additionaledit() {
  const ID = Cookies.get("Login_id");
  const navigate = useNavigate();

  const { loanId } = useParams();
  const { addId } = useParams();

  const [loan, setLoan] = useState([]);
  const [emp, setEmp] = useState({});

  const [loandata, setLoandata] = useState({
    newLoan: '',
    totalLoan: '',
    loanDate: '',
    payment: '',
    cheque: '',
    upi: '',
    account: ''
  });

  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [balance, setbalance] = useState('');
  const [bankDetail, setBankDetail] = useState({ accountNumber: '' });

  const loanref = useRef(null);
  const totalref = useRef(null);

  const [loanAmount, setLoanAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [loanDate, setLoanDate] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [chequeNumber, setChequeNumber] = useState('');
  const [upiId, setUpiId] = useState('');
  const [accno, setAccno] = useState('');

  const fetchLoan = () => {
    axios
      .get(`${config.base_url}/get_loanlatest_transaction/${loanId}/`)
      .then((res) => {
        if (res.data.status) {
          var ln = res.data.transaction;
          var loan = res.data.transaction.additional;
          const { new_loan, total_loan, new_date, payment_method, cheque_number, upi, bank_account } = res.data.transaction.additional;

          setLoandata({
            newLoan: new_loan,
            totalLoan: total_loan,
            loanDate: new_date,
            payment: payment_method,
            cheque: cheque_number,
            upi: upi,
            account: bank_account
          });

          setPaymentType(payment_method);
          setLoan(ln);
          setbalance(ln.balance)

          // Set initial values for state
          setLoanAmount(new_loan);
          setTotalAmount(total_loan);
          setLoanDate(new_date);
          setChequeNumber(cheque_number);
          setUpiId(upi);
          setAccno(bank_account);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An unexpected error occurred.",
          });
        }
      });
  };

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchBanks = () => {
    axios
      .get(`${config.base_url}/get_banks/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setBanks(res.data.bank);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBankDetails = (bankId) => {
    axios
      .get(`${config.base_url}/get_bank_details/${bankId}/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const { account_number } = res.data.bank[0];
          setBankDetail({ accountNumber: account_number });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }

      return `${year}-${month}-${day}`;
    };

    setLoanDate(getCurrentDate());
  }, []);

  useEffect(() => {
    // Calculate total amount whenever new loan amount or interest changes
    calculateTotalAmount(loanAmount, loandata.newLoan);
  }, [loanAmount, loandata.newLoan]);

  const handleBankChange = (id) => {
    setSelectedBank(id);
    fetchBankDetails(id);
  };

  const maskAccountNumber = (accountNumber) => {
    return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
  };

  const handleLoanDate = (event) => {
    setLoanDate(event.target.value);
  };

  const handleNewLoan = (e) => {
    const newLoan = parseFloat(e.target.value) || 0;
    const previousLoan = parseFloat(loandata.newLoan) || 0;
  
  
    const difference = newLoan - previousLoan;
  
    
    setLoandata(prevState => ({
      ...prevState,
      newLoan: newLoan
    }));
  
   
    setLoanAmount(newLoan);
  
    
    setLoan(prevState => ({
      ...prevState,
      balance: parseFloat(prevState.balance) + difference
    }));
  
    
    setTotalAmount(prevTotalAmount => prevTotalAmount + difference);
  
    
    calculateTotalAmount(newLoan, totalAmount);
  };
  const calculateTotalAmount = (newLoan, totalLoan) => {
    
    const principalAmount = parseFloat(newLoan) || 0;
    const interestAmount = parseFloat(totalLoan) || 0;
    const total = principalAmount + interestAmount;
    setTotalAmount(total);
  };

  const handlePaymentType = (event) => {
    const value = event.target.value;
    setPaymentType(value);

    if (value !== 'Cash' && value !== 'Cheque' && value !== 'UPI') {
      setSelectedBank(value);
      fetchBankDetails(value);
    } else {
      setSelectedBank('');
    }
  };

  const handleChequeNumber = (event) => {
    setChequeNumber(event.target.value);
  };

  const handleUpiId = (event) => {
    setUpiId(event.target.value);
  };

  const handleSubmit = (e) => {
    console.log('baaaallllaanncee=',balance)
    e.preventDefault();
    var u = {
      Id: ID,
      loan: loanId,
      payment_method: paymentType,
      cheque_number: chequeNumber,
      upi: upiId,
      account_number: bankDetail.accountNumber,
      balance_loan: balance,
      total_loan: totalref.current.value,
      new_loan: loandata.newLoan,
      date: loanDate,
      total_amount: totalAmount
    };

    axios.put(`${config.base_url}/issue_new_loan_edit/${addId}/`, u)
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Issued Loan Edited",
          });
          navigate(`/viewloan/${loanId}/`);
        } else if (res.data.message) {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        if (err.response && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  return (
    <>
      <FinBase />
      <div className="page-content mt-0 pt-0" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
        <div className="d-flex justify-content-end mb-1">
          <Link to={`/viewloan/${loanId}/`}>
            <i className="fa fa-times-circle text-white mx-4 p-1" style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}></i>
          </Link>
        </div>
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">EDIT ISSUED LOAN</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
          <div className="card-body">
            <form className="needs-validation px-1" onSubmit={handleSubmit} validate>
              <div className="row w-100">
                <div className="col-md-12 mx-0">
                  <div className="row">
                    <div className="col-md-6">
                      {/* Column 1: Employee selection, loan details */}
                      <div className="form-group">
                        <label htmlFor="loanAmount">Balance Loan</label>
                        <input
                          type="text"
                          id="loanAmount"
                          className="form-control"
                          value={loan.balance}
                          onChange={(e) => setbalance(e.target.value)}
                          readOnly
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="totalAmount">Total Loan</label>
                        <input
                          type="text"
                          id="totalAmount"
                          className="form-control"
                          value={loan.balance}
                          ref={totalref}
                          readOnly
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="paymentType">Payment Type</label>
                        <select
                          id="paymentType"
                          className="form-control"
                          value={paymentType}
                          onChange={handlePaymentType}
                          required
                        >
                          <option value="">Select Payment Type</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>

                          {banks.map((bank) => (
                            <option key={bank.id} value={bank.id} className="text-uppercase">
                              {bank.bank_name} - {maskAccountNumber(bank.account_number)}
                            </option>
                          ))}
                        </select>
                      </div>

                      {paymentType === 'Cheque' && (
                        <div className="form-group">
                          <label htmlFor="chequeNumber">Cheque Number</label>
                          <input
                            type="text"
                            id="chequeNumber"
                            className="form-control"
                            value={chequeNumber}
                            onChange={handleChequeNumber}
                          />
                        </div>
                      )}
                      {paymentType === 'UPI' && (
                        <div className="form-group">
                          <label htmlFor="upiId">UPI ID</label>
                          <input
                            type="text"
                            id="upiId"
                            className="form-control"
                            value={upiId}
                            onChange={handleUpiId}
                          />
                        </div>
                      )}

                      {selectedBank && !['Cash', 'Cheque', 'UPI'].includes(paymentType) && (
                        <div className="form-group">
                          <label htmlFor="accountNumber">Account Number</label>
                          <input
                            type="text"
                            id="accountNumber"
                            className="form-control"
                            value={bankDetail.accountNumber}
                            onChange={(e) => setAccno(e.target.value)}
                            readOnly
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="loanAmount">New Loan</label>
                        <input
                          type="number"
                          id="loanAmount"
                          className="form-control"
                          value={loandata.newLoan}
                          onChange={handleNewLoan}
                          required
                        />
                      </div>

                      {/* Column 2: Payment details, monthly cutting, file upload */}
                      <div className="form-group">
                        <label htmlFor="loanDate">Date</label>
                        <input
                          type="date"
                          id="loanDate"
                          className="form-control"
                          value={loanDate}
                          onChange={handleLoanDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-light"
                        type="submit"
                        style={{ width: "50%", height: "fit-content" }}
                      >
                        EDIT
                      </button>
                      <Link
                        to={`/viewloan/${loanId}/`}
                        className="btn btn-outline-secondary ml-1 text-light"
                        style={{ width: "fit-content", height: "fit-content" }}
                      >
                        CANCEL
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Additionaledit;