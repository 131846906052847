import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import html2pdf from "html2pdf.js";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/cashinhand.css"



function CashInHandStatement() {
  const ID = Cookies.get("Login_id");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleButtonClick = () => {
    navigate('/cashinhandstatement');
  };

  const sortData = (data) => {
    // Sort with "Add cash" and "Reduce cash" at the top
    return data.sort((a, b) => {
      if (a.type === 'Add cash') return -1;
      if (b.type === 'Add cash') return 1;
      if (a.type === 'Reduce cash') return -1;
      if (b.type === 'Reduce cash') return 1;
      return 0;
    });
  };

  const handleDateFilter = () => {
    fetchCustomizedData(startDate, endDate);
  };

  const fetchHolder = (id) => {
    if (!id) {
      console.error("Invalid ID:", id);
      return;
    }

    axios.get(`${config.base_url}/cash_in_hand/${id}/`)
      .then((res) => {
        if (res.data.status) {
          const sortedData = sortData(res.data.reportData);
          setReportData(sortedData); // Array of invoices
          setOtherDetails(res.data.otherDetails); // Additional details
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
        } else {
          Swal.fire({
            icon: "error",
            title: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        Swal.fire({
          icon: "error",
          title: "An error occurred while fetching the data.",
        });
      });
  };

  useEffect(() => {
    if (ID) {
      fetchHolder(ID);
    } else {
      console.error("No Login ID found in cookies");
      // Handle the case where ID is not found, e.g., redirect to login
    }
  }, [ID]);

  const fetchCustomizedData = () => {
    const params = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
    };

    axios.get(`${config.base_url}/cash_in_hand_customized/${ID}/`, { params })
      .then((res) => {
        if (res.data.status) {
          const sortedData = sortData(res.data.reportData);
          setReportData(sortedData);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const toggleContent = () => {
    var contentDiv = document.getElementById("contentDiv");
    contentDiv.style.display = contentDiv.style.display === "block" ? "none" : "block";
  };

  const refreshAll = () => {
    setReportData([]);
    fetchHolder(ID);
  };
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/cash_statement_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  function reportPDF() {
    // Get the values of startDate and endDate
    var st = startDate || "";  // Default to empty string if undefined
    var en = endDate || "";    // Default to empty string if undefined

    // Build the date part of the filename based on available values
    var date = "";
    if (st && en) {
        date = `_${st}_${en}`;
    } else if (st) {
        date = `_${st}`;
    } else if (en) {
        date = `_${en}`;
    }

    var element = document.getElementById("printReport");
    var opt = {
        margin: [0.5, 0.3, 0.3, 0.5],
        filename: "CASH_IN_HAND_Details" + date + ".pdf", // Ensure no null values
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };

    // Generate the PDF
    html2pdf().set(opt).from(element).save();
}

  
  const printSection = () => {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title>Print Report</title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head><body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  };
  

  return (
    <>
      <FinBase />
      
      <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
      <div className="d-flex justify-content-end mb-1">
          <Link to={"/cashinhand"}>
            <i
              className="fa fa-times-circle text-white mx-3 p-1"
              style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}
            ></i>
          </Link>
        </div>
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">CASH IN HANDS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
  <div className="card-body">
    <div className="container-fluid">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
        <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
          <input
            type="date"
            className="form-control mr-2 mb-2 mb-md-0"
            value={startDate}
            style={{ width: '150px' }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control mr-2 mb-2 mb-md-0"
            value={endDate}
            style={{ width: '150px' }}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-outline-secondary text-white mb-2 mb-md-0"
            onClick={handleDateFilter}
            style={{ width: '150px', height: '38px' }}
          >
            Customize
          </button>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-md-end mt-2 mt-md-0">
  <button
    type="button"
    className="btn btn-outline-secondary text-grey mb-2 mb-md-0"
    id="exportBtn"
    onClick={reportPDF}
    style={{ padding: '5px 10px', fontSize: '12px' }} // Adjusted padding and font size
  >
    <i className="fa fa-file-pdf-o"></i> PDF
  </button>
  <button
    type="button"
    className="btn btn-outline-secondary text-grey mb-2 mb-md-0 ml-2"
    onClick={printSection}
    style={{ padding: '5px 10px', fontSize: '12px' }} // Adjusted padding and font size
  >
    <i className="fa fa-print"></i> Print
  </button>
  <div className="dropdown">
    <button
      type="button"
      className="btn btn-outline-secondary text-grey dropdown-toggle mb-2 mb-md-0 ml-2"
      id="shareBtn"
      data-toggle="dropdown"
      style={{ padding: '5px 10px', fontSize: '12px' }} // Adjusted padding and font size
    >
      <i className="fa fa-share-alt"></i> Share
    </button>
    <ul
      className="dropdown-menu ml-2"
      style={{ backgroundColor: "black" }}
      id="listdiv"
    >
      <a
        href={shareUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <li
          style={{
            textAlign: "center",
            color: "#e5e9ec",
            cursor: "pointer",
          }}
        >
          WhatsApp
        </li>
      </a>
      <li
        style={{
          textAlign: "center",
          color: "#e5e9ec",
          cursor: "pointer",
        }}
        data-toggle="modal"
        data-target="#shareToEmail"
      >
        Email
      </li>
    </ul>
  </div>
</div>

      </div>
    </div>
  </div>

  <div className="card-2 bg-white radius-15" style={{ width: "95%", maxWidth: "950px", margin: "20px auto" }} id="printReport">
  <div className="table-responsive">
    <div style={{ padding: "20px" }} className="card-head bg-info">
    <h1
  className="text-center"
  style={{
    fontSize: "clamp(1.5rem, 5vw, 3rem)",
    fontWeight: "bold",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    wordWrap: "break-word"
  }}
>
  CASH IN HAND
</h1>

    </div>
    {otherDetails && otherDetails.company_details ? (
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center" style={{ padding: "20px" }}>
        <div>
          <h3 className="text-secondary" style={{ fontFamily: "Arial" }}>{otherDetails.company_details.company_name}</h3>
          <h5 className="text-dark" style={{ fontFamily: "Arial" }}>{otherDetails.company_details.company_address}</h5>
          <h5 className="text-dark" style={{ fontFamily: "Arial" }}>{otherDetails.company_details.pincode}</h5>
          <h5 className="text-dark" style={{ fontFamily: "Arial" }}>{otherDetails.company_details.company_contact}</h5>
        </div>
        <h2 className="text-info text-md-right mt-3 mt-md-0" style={{ fontFamily: "Arial" }}>Total Balance: {otherDetails.totalBalance}</h2>
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center" style={{ padding: "20px" }}>
        <h3 className="text-secondary" style={{ fontFamily: "Arial" }}>Loading company details...</h3>
      </div>
    )}
    <div className="card-body">
      <table
        className="table table-responsive-md mt-4"
        id="itemsTable"
        style={{ textAlign: "center", borderCollapse: "collapse", width: "100%", margin: "0 auto" }}
      >
        <thead>
          <tr style={{ backgroundColor: '#00CCCC' }}>
            <th className="text-dark" style={{ border: "none" }}>DATE</th>
            <th className="text-dark" style={{ border: "none" }}>TYPE</th>
            <th className="text-dark" style={{ border: "none" }}>NAME</th>
            <th className="text-dark" style={{ border: "none" }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {reportData && reportData.length > 0 ? (
            reportData.map((item, index) => (
              <tr key={index} style={{ cursor: "pointer", border: "#B5B5B5", backgroundColor: '#B5B5B5' }}>
                <td className="text-dark">{item.date}</td>
                <td className="text-dark">{item.type}</td>
                <td className="text-dark">{item.name}</td>
                <td className="text-dark">{item.total}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-dark">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>



</div>
</div>
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashInHandStatement;
