import React, { useState, useEffect, useRef } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function RepaymentEdit() {
  const ID = Cookies.get("Login_id");
  const navigate = useNavigate();
  const { loanId, rpayId } = useParams();

 
  const [loan, setLoan] = useState({});
  const [emp, setEmp] = useState({});
  const [intrst, setInterst] = useState('');
  const [rpay, setRpay] = useState({
    interest: '',
    date: '',
    payment_method: '',
    cheque_number: '',
    upi: '',
    accountNumber: '',
    total_amount: '',
    principal:''
  });
  const [employees, setEmployees] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [bankDetail, setBankDetail] = useState({ accountNumber: '' });
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [chequeNumber, setChequeNumber] = useState('');
  const [upiId, setUpiId] = useState('');
  const [princ, setPrinc] = useState('');
  const [bankId, setBankId] = useState('');
  const [accno, setAccno] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [loanDate, setLoanDate] = useState('');
  const [paymentType, setPaymentType] = useState('');

  const loanref = useRef(null);
  const empref = useRef(null);

  const fetchLoan = () => {
    axios
      .get(`${config.base_url}/fetch_loan_data/${loanId}/`)
      .then((res) => {
        if (res.data.status) {
          var hldr = res.data.loan;
          var em = res.data.loan.employee;
          setLoan(hldr);
          setEmp(em);
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An unexpected error occurred.",
          });
        }
      });
  };

  const fetchRepayment = () => {
    axios
      .get(`${config.base_url}/fetch_repayment_data/${rpayId}/`)
      .then((res) => {
        if (res.data.status) {
          const { interest_amount, payment_date, payment_method, cheque_number, upi, account_number, total_amount, bank,principle_amount } = res.data.loan;
          setRpay({
            interest: interest_amount,
            date: payment_date,
            payment_method,
            cheque_number,
            upi,
            accountNumber: account_number,
            bank: bank,
            total_amount,
            principal:principle_amount
          });
          setInterst(interest_amount);
          setChequeNumber(cheque_number);
          setUpiId(upi);
          setBankId(bank);
          setAccno(account_number);
          setTotalAmount(total_amount);
          setLoanDate(payment_date);
          setPaymentType(payment_method);
          setPrinc(principle_amount)
        
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An unexpected error occurred.",
          });
        }
      });
  };

  const fetchEmployee = () => {
    axios
      .get(`${config.base_url}/get_loan_employee/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setEmployees(res.data.employee);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBanks = () => {
    axios
      .get(`${config.base_url}/get_banks/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setBanks(res.data.bank);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBankDetails = (bankId) => {
    axios
      .get(`${config.base_url}/get_bank_details/${bankId}/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const { account_number } = res.data.bank[0];
          setBankDetail({
            accountNumber: account_number,
          });
          setAccno(account_number);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLoan();
    fetchEmployee();
    fetchBanks();
    fetchRepayment();
  }, [rpayId]);

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      return `${year}-${month}-${day}`;
    };
    setLoanDate(getCurrentDate());
  }, []);

  useEffect(() => {
    if (paymentType === 'Cheque' && selectedBank) {
      fetchBankDetails(selectedBank);
    }
  }, [paymentType, selectedBank]);

  useEffect(() => {
    if (paymentType && paymentType !== 'Cash' && paymentType !== 'Cheque' && paymentType !== 'UPI' && selectedBank) {
      fetchBankDetails(paymentType);
    }
  }, [paymentType]);

  const handleBankChange = (event) => {
    const id = event.target.value;
    setSelectedBank(id);
    if (paymentType !== 'Cash') {
      fetchBankDetails(id);
    }
  };

  const maskAccountNumber = (accountNumber) => {
    return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
  };

  const handleLoanDate = (event) => {
    setLoanDate(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  const handlePaymentType = (event) => {
    const value = event.target.value;
    setPaymentType(value);

    if (value === 'Cash') {
      setSelectedBank('');
      setAccno('');
    } else if (value === 'Cheque') {
      setAccno(bankDetail.accountNumber);
    } else if (value === 'UPI') {
      setAccno('');
    } else {
      setSelectedBank(value);
      fetchBankDetails(value);
    }
  };

  const handleChequeNumber = (event) => {
    setChequeNumber(event.target.value);
  };

  const handleUpiId = (event) => {
    setUpiId(event.target.value);
  };

  const handleInterestChange = (e) => {
    const newInterest = e.target.value;
    setInterst(newInterest);
    calculateTotalAmount(rpay.principal, newInterest);
  };

  const calculateTotalAmount = (principal, interest) => {
    const principalAmount = parseFloat(principal) || 0;
    const interestAmount = parseFloat(interest) || 0;
    const total = principalAmount + interestAmount;
    setTotalAmount(total);
  };
  const handlePrincple =(e)=>
  {
   const p = e.target.value;
    setRpay({ principal: e.target.value })
    calculateTotalAmount(p,intrst)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const u = {
      Id: ID,
      loan: loanId,
      
      employee: empref.current.value,
      interest_amount: intrst,
      payment_method: paymentType,
      cheque_number: chequeNumber,
      upi: upiId,
      account_number: accno,  
      loan_amount: rpay.principal,
      date: loanDate,
      total_amount: totalAmount
    };

    axios.put(`${config.base_url}/edit_repayment/${rpayId}/`, u)
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Repayment Edited",
          });
          navigate(`/viewloan/${loanId}/`);
        } else {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An unexpected error occurred.",
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="d-flex justify-content-end mb-1">
          <Link to={`/viewloan/${loanId}/`}>
            <i
              className="fa fa-times-circle text-white mx-4 p-1"
              style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}
            ></i>
          </Link>
        </div>
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">EDIT REPAYMENT DUE</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
          <div className="card-body">
            <form
              className="needs-validation px-1"
              onSubmit={handleSubmit}
              validate
            >
              <div className="row w-100">
                <div className="col-md-12 mx-0">
                  <div className="row">
                    <div className="col-md-6">
                      {/* Column 1: Employee selection, loan details */}
                      <div className="form-group">
                        <label htmlFor="employee">Employee</label>
                        <div className="d-flex align-items-center">
                          <select
                            id="employee"
                            className="form-control"
                            value={selectedEmployee || emp.id}  // Default to empty string if selectedEmployee is undefined
                            ref={empref}
                            onChange={handleEmployeeChange}
                          >
                            <option value="">Select Employee</option>
                            {employees.map((emp) => (
                              <option key={emp.id} value={emp.id}>
                                {emp.first_name} {emp.last_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="interest">Interest Amount</label>
                        <input
                          type="text"
                          id="interest"
                          className="form-control"
                          value={intrst}  // Use `intrst` state for Interest Amount
                          onChange={handleInterestChange}  // Call `handleInterestChange` when changed
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="paymentType">Payment Type</label>
                        <select
                          id="paymentType"
                          className="form-control"
                          value={paymentType}  // Set value to `paymentType` state
                          onChange={handlePaymentType}
                        >
                          <option value="">Select Payment Type</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>
                          {banks.map((bank) => (
                            <option key={bank.id} value={bank.id} className="text-uppercase">
                              {bank.bank_name} - {maskAccountNumber(bank.account_number)}
                            </option>
                          ))}
                        </select>
                      </div>
                      {paymentType === 'Cheque' && (
                        <div className="form-group">
                          <label htmlFor="chequeNumber">Cheque Number</label>
                          <input
                            type="text"
                            id="chequeNumber"
                            className="form-control"
                            value={chequeNumber}  // Bind `chequeNumber` state
                            onChange={handleChequeNumber}
                          />
                        </div>
                      )}
                      {paymentType === 'UPI' && (
                        <div className="form-group">
                          <label htmlFor="upiId">UPI ID</label>
                          <input
                            type="text"
                            id="upiId"
                            className="form-control"
                            value={upiId}  // Bind `upiId` state
                            onChange={handleUpiId}
                          />
                        </div>
                      )}
                      {(paymentType !== 'Cash' && paymentType !== 'Cheque' && paymentType !== 'UPI' && selectedBank) && (
                        <div className="form-group">
                          <label htmlFor="accountNumber">Account Number</label>
                          <input
                            type="text"
                            id="accountNumber"
                            className="form-control"
                            value={accno}  
                            onChange={(e) => setAccno(e.target.value)}
                            readOnly
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="totalAmount">Total Amount</label>
                        <input
                          type="text"
                          id="totalAmount"
                          className="form-control"
                          value={totalAmount}  // Display `totalAmount` state
                        />
                      </div>
                     
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="loanAmount">Principal Amount</label>
                        <input
                          type="number"
                          id="loanAmount"
                          className="form-control"
                          value={rpay.principal}  // Bind Principal Amount from `loan` state
                          onChange={handlePrincple}
                          //onChange={(e) => setRpay({ principal: e.target.value })}
                           // Make read-only as it should not change
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="loanDate">Date</label>
                        <input
                          type="date"
                          id="loanDate"
                          className="form-control"
                          value={loanDate}  // Bind `loanDate` state
                          onChange={handleLoanDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-light"
                        type="submit"
                        style={{ width: "50%", height: "fit-content" }}
                      >
                        EDIT
                      </button>
                      <Link
                        to={`/viewloan/${loanId}/`}
                        className="btn btn-outline-secondary ml-1 text-light"
                        style={{ width: "fit-content", height: "fit-content" }}
                      >
                        CANCEL
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default RepaymentEdit;