import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import FinBase from '../FinBase';
import config from '../../../functions/config';

function Edit_Holiday() {
    const { holidayId } = useParams();
    
    const [holidayDetails, setHolidayDetails] = useState({
        title: '',
        startDate: '',
        endDate: ''
    });

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    
    const query = useQuery();
    const ID = query.get("ID");
    console.log('id', ID);

    useEffect(() => {
        axios.get(`${config.base_url}/get_holidayedit/${holidayId}/`)
            .then(response => {
                if (response.data.status) {
                    const data = response.data.data;
                    setHolidayDetails({
                        title: data.Holiday_Name,
                        startDate: data.Start_Date,
                        endDate: data.End_Date
                    });
                } else {
                    console.error("Error fetching holiday details:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching holiday details:", error);
            });
    }, [holidayId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHolidayDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedDetails = { ...holidayDetails, ID }; // Add ID to holidayDetails
        axios.post(`${config.base_url}/update_holiday/${holidayId}/`, updatedDetails)
            .then(response => {
                if (response.data.status) {
                    window.history.back(); // Redirect to holidays list page
                } else {
                    console.error("Error updating holiday details:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error updating holiday details:", error);
            });
    };

    return (
        <>
            <FinBase />
            <div className="page-content" style={{ backgroundColor: '#2f516f', minHeight: "100vh" }}>
                <div className="card radius-15 h-20">
                    <div className="row">
                        <div className="col-md-12">
                            <center><h2 className="mt-3">EDIT HOLIDAY</h2></center>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="card radius-15">
                    <div className="card-body">
                        <form method="post" className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-12 mx-0">
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="title" style={{ color: 'white' }}>Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white'}}
                                                required
                                                id="title"
                                                value={holidayDetails.title}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="startDate" style={{ color: 'white' }}>Start Date</label>
                                            <input
                                                type="date"
                                                name="startDate"
                                                className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white' }}
                                                required
                                                id="startDate"
                                                value={holidayDetails.startDate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="endDate" style={{ color: 'white' }}>End Date</label>
                                            <input
                                                type="date"
                                                name="endDate"
                                                className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white' }}
                                                required
                                                id="endDate"
                                                value={holidayDetails.endDate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 d-flex justify-content-center">
                                            <button className="btn btn-outline-secondary text-light" type="submit" style={{ width: 'fit-content', height: 'fit-content' }}>UPDATE</button>
                                            <button type="button" onClick={() => window.history.back()} className="btn btn-outline-secondary ml-1 text-light" style={{ width: 'fit-content', height: 'fit-content' }}>CANCEL</button>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Edit_Holiday;
