import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";

import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
//import "../../styles/loanStatement.css";
import * as XLSX from "xlsx";
//import logo from '../../../../../logo.svg';
function ViewLoanHolder() {
  const ID = Cookies.get("Login_id");
  const { itemId } = useParams();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateDisplay, setStartDateDisplay] = useState('');
  const [endDateDisplay, setEndDateDisplay] = useState('');
  const [finalBal, setFinalBal] = useState(0);


  const [isModalOpen, setIsModalOpen] = useState(false);

  const [itemDetails, setItemDetails] = useState({});
  const [empDetails, setempDetails] = useState({});
  const [compDetails, setCompDetails] = useState({});
  console.log('item details=',itemDetails)
  const [comments, setComments] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [history, setHistory] = useState({
    action: "",
    date: "",
    doneBy: "",
  });
  //console.log('action=',history.action)
  const [trans, setTrans] = useState([])
  const [trans1, setTrans1] = useState([])
  const [loantrans, setLoanTrans] = useState([])
  const [finalBalance, setFinalBalance] = useState('0.00');
  const fetchTransactions = () => {
    axios
      .get(`${config.base_url}/get_loan_account_transaction/${itemId}/`)
      .then((res) => {
        console.log("trans==", res);
        if (res.data.status) {
          
          setTrans(res.data.transaction);
          setTrans1(res.data.transaction)
           setLoanTrans(res.data.transaction.loan)
           setFinalBal(res.data.bal)
           
           
            
         
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (trans.length > 0) {
        const balance = trans[trans.length - 1].balance;
        setFinalBalance(balance.toFixed(2));  
    } else {
        setFinalBalance('0.00');  
    }
}, [trans]);




  const fetchItemDetails = () => {
    axios
      .get(`${config.base_url}/fetch_loan_account_details/${itemId}/`)
      .then((res) => {
        console.log("ITEM DATA=", res);
        if (res.data.status) {
          var itm = res.data.item;
          var hist = res.data.history;
          var em = res.data.item.holder;
          var comp = res.data.comp
          setempDetails(em)
          setCompDetails(comp)
          
          if (itm.attach_file) {
            var url = `${config.base_url}/${itm.attach_file}`;
            setFileUrl(url);
          }
          var cmt = res.data.comments;
          setComments([]);
          cmt.map((c) => {
           setComments((prevState) => [...prevState, c]);
          });
          setItemDetails(itm);
          if (hist) {
            setHistory(hist);
            //console.log('his=',res.data.item)
          }
          
         
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchTransactions();
    fetchItemDetails();
    
  }, []);



  useEffect(() => {
    if (startDate && endDate) {
      console.log('start=', startDate, 'end=', endDate);

      const fetchData = async () => {
        try {
          // Request with query parameters
          const response = await axios.get(`${config.base_url}/fetch_loan_account_statement/${itemId}/`, {
            params: {
              startDate: startDate,
              endDate: endDate
            }
          });

          console.log("ITEM STATEMENT=", response);

          if (response.data.status) {
            // Clear dates after fetching data
            //setStartDate('');
            //setEndDate('')

            // Destructure data from the response
            const { item, comp,emp ,final_balance,start_date,end_date} = response.data;

            
            setempDetails(emp);
            setCompDetails(comp);
            setTrans1(item);
            setFinalBal(final_balance);
            setStartDateDisplay(start_date);
            setEndDateDisplay(end_date);
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data.message
            });
          }
        } catch (error) {
          console.log("ERROR=", error);

          // Handle errors and display a message
          if (error.response && !error.response.data.status) {
            Swal.fire({
              icon: 'error',
              title: `${error.response.data.message}`,
            });
          }
        }
      };

      fetchData();
    }
  }, [startDate, endDate, itemId]);



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  function stockValue(stock, rate) {
    var valueElement = document.getElementById("stockValue");
    if (!isNaN(stock) && !isNaN(rate)) {
      valueElement.innerText = parseFloat(stock * rate);
    } else {
      valueElement.innerText = 0;
    }
  }
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  function handleFileModalSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Id", ID);
    formData.append("loan_id", itemId);
    if (file) {
      formData.append("file", file);
    }

    axios
      .post(`${config.base_url}/add_loan_account_attachment/`, formData)
      .then((res) => {
        console.log("FILE RES==", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "File Added.",
          });
          setFile(null);
          document.getElementById("fileModalDismiss").click();
          fetchItemDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }


  const changeStatus = (status) => {
    var st = {
      id: itemId,
      status: status,
    };
    axios
      .post(`${config.base_url}/change_loan_account_status/`, st)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Status Updated",
          });
          fetchItemDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  const [comment, setComment] = useState("");
  const saveItemComment = (e) => {
    e.preventDefault();
    var cmt = {
      Id: ID,
      loan: itemId,
      comment: comment,
    };
    axios
      .post(`${config.base_url}/add_loan_account_comment/`, cmt)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Comment Added",
          });
          setComment("");
          fetchItemDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  function handleDeleteItem(id) {
    Swal.fire({
      title: `Do you want to delete?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_loan_account/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Item Deleted successfully",
            });
            navigate("/loanholderlist");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  function deleteComment(id) {
    Swal.fire({
      title: "Delete Comment?",
      text: "Are you sure you want to delete this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_loan_account_comment/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Comment Deleted",
            });
            fetchItemDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function overview() {
    document.getElementById("overview").style.display = "block";
    document.getElementById("Transaction").style.display = "none";
    document.getElementById("statement").style.display = "none";
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("attachBtn").style.display = "block";
    document.getElementById("paymentBtn").style.display = "none";
    document.getElementById("exportBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "block";
    document.getElementById("historyBtn").style.display = "block";
    document.getElementById("active").style.display = "block";
    document.getElementById("commentBtn").style.display = "block";
    document.getElementById("statusBtn").style.display = "block";
    document.getElementById("overviewBtn").style.backgroundColor =
      "rgba(22,37,50,255)";
    document.getElementById("transactionBtn").style.backgroundColor =
      "transparent";
      document.getElementById("statementBtn").style.backgroundColor =
      "transparent";
  }


  function statement() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("Transaction").style.display = "none";
    document.getElementById("statement").style.display = "block";
    
    document.getElementById("printBtn").style.display = "block";
    document.getElementById("pdfBtn").style.display = "block";
    document.getElementById("shareBtn").style.display = "block";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("attachBtn").style.display = "none";
    document.getElementById("paymentBtn").style.display = "none";
    document.getElementById("exportBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("active").style.display = "none";
    document.getElementById("commentBtn").style.display = "none";
    document.getElementById("statusBtn").style.display = "none";
    document.getElementById("statementBtn").style.backgroundColor =
      "rgba(22,37,50,255)";
    document.getElementById("transactionBtn").style.backgroundColor =
      "transparent";
      document.getElementById("overviewBtn").style.backgroundColor =
      "transparent";
     
  }




  function transaction() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("Transaction").style.display = "block";
    document.getElementById("statement").style.display = "none";
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("attachBtn").style.display = "none";
    document.getElementById("paymentBtn").style.display = "block";
    document.getElementById("exportBtn").style.display = "block";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("active").style.display = "none";
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("commentBtn").style.display = "none";
    document.getElementById("statusBtn").style.display = "none";
    document.getElementById("overviewBtn").style.backgroundColor =
      "transparent";
    document.getElementById("transactionBtn").style.backgroundColor =
      "rgba(22,37,50,255)";
      document.getElementById("statementBtn").style.backgroundColor =
      "transparent";
  }

  function printSection(sectionId) {
    document.body.style.backgroundColor = "white";
    document.querySelector(".page-content").style.backgroundColor = "white";
    var transactionElements = document.querySelectorAll(
      "#transaction, #transaction *"
    );
    transactionElements.forEach(function (element) {
      element.style.color = "black";
    });

    var printContents = document.getElementById(sectionId).innerHTML;

    var printerDiv = document.createElement("div");
    printerDiv.className = "printContainer";
    printerDiv.innerHTML = printContents;

    document.body.appendChild(printerDiv);
    document.body.classList.add("printingContent");

    window.print();

    document.body.removeChild(printerDiv);
    document.body.classList.remove("printingContent");

    transactionElements.forEach(function (element) {
      element.style.color = "white";
    });
    document.querySelector(".page-content").style.backgroundColor = "#2f516f";
  }

  function printSheet() {
    var divToPrint = document.getElementById("printOnly");
    var printWindow = window.open("", "", "height=700,width=1000");
  
    printWindow.document.write("<html><head><title>Print Statement</title>");
    printWindow.document.write(`
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
      <style>
        body {
          font-family: 'Gluten', sans-serif;
        }
        .text-uppercase {
          text-transform: uppercase;
        }
        .text-center {
          text-align: center;
        }
        .text-md-left {
          text-align: left;
        }
        .p-4 {
          padding: 1.5rem;
        }
        .my-5 {
          margin-top: 3rem;
          margin-bottom: 3rem;
        }
        .mt-3 {
          margin-top: 1rem;
        }
        .mt-1 {
          margin-top: 0.25rem;
        }
        .mb-4 {
          margin-bottom: 1.5rem;
        }
        .bb {
          border-bottom: 2px solid #000000;
        }
        .ribbon-inner {
          text-transform: uppercase;
          color: #000000;
          background-color: #22b8d1;
          padding: 5px 10px;
          width: 100px;
          font-size: 20px;
          border-radius: 5px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        table th, table td {
          padding: 0.75rem;
          text-align: center;
        }
        table thead {
          background-color: #22b8d1;
        }
        table thead th {
          color: #000000;
        }
        .cart-bg {
          margin-top: 2rem;
        }
      </style>
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.innerHTML);  // Use innerHTML instead of outerHTML to avoid extra HTML tags
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener('afterprint', function() {
      printWindow.close();
    });
  }
  function itemTransactionPdf() {
    console.log('startttttt dddTTTTE :',startDate)
    const params = {};
    if (startDate) params.start_date = startDate;  
    if (endDate) params.end_date = endDate;      

    
    const url = `${config.base_url}/loan_account_pdf/${itemId}/${ID}/`;

    axios
      .get(url, {
        params: params,  // Pass params object to include start_date and end_date only if available
        responseType: "blob",
      })
      .then((res) => {
        console.log("PDF RES=", res);

        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = `loan_transactions_${itemId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }


  function searchTable(){
    var rows = document.querySelectorAll('#itemTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  function exportToExcel() {
    const table = document.getElementById("itemTable");
  
    // Convert table to a worksheet
    let ws = XLSX.utils.table_to_sheet(table);
    
    // Remove the last column (index 6) from the worksheet
    const colToRemove = 6; // 0-based index for the 6th column

    // Delete the 6th column (index 5) from the worksheet
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
        const cellAddress = {c: colToRemove, r: R};
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        delete ws[cellRef];
    }

    // Adjust the range reference to exclude the 6th column
    range.e.c--;
    ws['!ref'] = XLSX.utils.encode_range(range);

    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    
    const fileName = `${itemDetails.account_name}  - TRANSACTIONS.xlsx`;
    XLSX.writeFile(wb, fileName);
}

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
  e.preventDefault();

    var emailsString = emailIds.trim();

    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];

        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }

      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        // document.getElementById("share_to_email_form").submit();
        var em = {
          itemId: itemId,
          Id: ID,
          start_date : startDate,
          end_date : endDate,
          email_ids: emailIds,
          email_message: emailMessage,
        };
        axios
          .post(`${config.base_url}/share_loan_account_email/`, em)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setIsModalOpen(false);
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (
              err.response &&
              err.response.data &&
              !err.response.data.status
            ) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }


  const handleActionClick = (item, actionType) => {
    switch (actionType) {
      case 'edit':
        if (item.bank_type === 'EMI PAID') {
          navigate(`/repaymentLoanEdit/${item.id}/`)
        } else if (item.bank_type === 'ADDITIONAL LOAN ISSUED') {
          navigate(`/additionalLoanEdit/${item.id}/`)
        } else {
          navigate(`/loanaccountedit/${item.id}/`)
          
        }
        break;

      case 'history':
        if (item.bank_type === 'EMI PAID') {
          navigate(`/loanrepayhistory/${item.id}/${itemId}/`)
        } else if (item.bank_type === 'ADDITIONAL LOAN ISSUED') {
          navigate(`/loanaddhistory/${item.id}/${itemId}/`)
        } else {
          navigate(`/loanholderhistory/${itemId}/`)
          
        }
        break;

      case 'delete':
        if (item.bank_type === 'EMI PAID') {
          deleteRepaymentEntry(item.id);  
        } else if (item.bank_type === 'ADDITIONAL LOAN ISSUED') {
          deleteAdditionalEntry(item.id);  
        } else {
          deleteLoanEntry(itemId);  
        }
        break;

      default:
        console.log('Unknown action');
    }
  };

  const deleteRepaymentEntry = (id) => {
    Swal.fire({
      title: `Do you want to delete?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_repayment_loan/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Transaction Deleted successfully",
            });
            fetchTransactions()
            //navigate(`/viewloan/${itemId}/`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
   
    console.log(`Deleting repayment entry with ID: ${id}`);
  };
  
  const deleteAdditionalEntry = (id) => {
    Swal.fire({
      title: `Do you want to delete?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_additinal_loan/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Transaction Deleted successfully",
            });
            fetchTransactions()
            //navigate(`/viewloan/${itemId}/`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
   
   
    console.log(`Deleting additional loan entry with ID: ${id}`);
  };
  
  const deleteLoanEntry = (id) => {
    // Replace with actual API call or delete logic
    Swal.fire({
      title: `Do you want to delete?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_loan_account/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Transaction Deleted successfully",
            });
           
            
            navigate(`/loanholderlist/`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
   
   
    console.log(`Deleting  loan entry with ID: ${id}`);
  };
  




  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/loanholderlist"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-7 d-flex j d-flex d-flex  d-flex  d-flex flex-column flex-lg-row">
                  <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                        backgroundColor:"rgba(22,37,50,255)"
                      }}
                      onClick={transaction}
                      id="transactionBtn"
                    >
                      Transactions
                    </a>
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                        backgroundColor: "transparent",
                      }}
                      onClick={overview}
                      id="overviewBtn"
                    >
                      Overview
                    </a>

                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                        backgroundColor: "transparent",
                      }}
                      onClick={statement}
                      id="statementBtn"
                    >
                      Statement
                    </a>
                    
                  </div>

                  <div className="col-md-5 d-flex justify-content-end d-flex justify-content-end d-flex d-flex justify-content-end d-flex justify-content-end d-flex flex-column flex-lg-row">
                    <a id="active" style={{display:"none"}}>
                    {itemDetails.status == "Inactive" ? (
                      <a
                        onClick={() => changeStatus("Active")}
                        id="statusBtn"
                        style={{
                          display:"none",
                          height: "fit-content",
                          width: "fit-content",
                        }}
                        className="ml-2 fa fa-ban btn btn-outline-secondary text-grey "
                        role="button"
                      >
                        &nbsp;Inactive
                      </a>
                    ) : (
                      <a
                        onClick={() => changeStatus("Inactive")}
                        id="statusBtn"
                        style={{
                          display:"none",
                          height: "fit-content",
                          width: "fit-content",
                        }}
                        className="ml-2 fa fa-check-circle btn btn-outline-secondary text-grey"
                        role="button"
                      >
                        &nbsp;Active
                      </a>
                    )}
                   </a>
                    
                    <a
                      onClick={itemTransactionPdf}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-file"
                      role="button"
                      id="pdfBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      &nbsp;PDF
                    </a>

                   
                    <div
                      className="dropdown p-0 nav-item"
                      id="attachBtn"
                      style={{ display: "block",display:"none" }}
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-paperclip"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Attach
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                      >
                        <a
                          className="dropdown-item fa fa-paperclip"
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#attachFileModal"
                        >
                          &nbsp; Attach file
                        </a>
                        {fileUrl ? (
                          <a
                            className="dropdown-item fa fa-download"
                            style={{ cursor: "pointer" }}
                            download
                            target="_blank"
                            href={fileUrl}
                          >
                            &nbsp; Download file
                          </a>
                        ) : null}
                      </ul>
                    </div>





                    <div
                      className="dropdown p-0 nav-item"
                      id="paymentBtn"
                     
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey "
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Make Payment
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                      >
                       <Link
     to={`/loanrepayment/${itemId}/`}
      className="dropdown-item"
      style={{ cursor: "pointer" }}
    >
      &nbsp; Repayment Due
    </Link>
                       
    <Link
      to={`/loanadditional/${itemId}/`}
      className="dropdown-item"
      style={{ cursor: "pointer" }}
    >
      &nbsp; Issue New Loan
    </Link>
                        
                      </ul>
                    </div>


                    <button
                    type="button"
                    style={{ width: "fit-content", height: "fit-content" }}
                    className="btn btn-outline-secondary text-grey"
                    id="exportBtn"
                    onClick={exportToExcel}
                  >
                    <i className="fa fa-table"></i> Export To Excel
                  </button>



                        





                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                      role="button"
                      id="printBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      onClick={() => printSheet()}
                    >
                      &nbsp;Print
                    </a>
                    <div
                      className="dropdown p-0 nav-item"
                      id="shareBtn"
                      style={{ display: "none" }}
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Share
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                        id="listdiv"
                      >
                        {/* <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                        >
                          WhatsApp
                        </li> */}
                        <a
                          href={shareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li
                            style={{
                              textAlign: "center",
                              color: "#e5e9ec",
                              cursor: "pointer",
                            }}
                          >
                            WhatsApp
                          </li>
                        </a>
                        <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsModalOpen(true)}
                          data-toggle="modal"
                          data-target="#shareToEmail"
                        >
                          Email
                        </li>
                      </ul>
                    </div>
                    <Link
                      to= {`/loanaccountedit/${itemId}/`}
                      className="ml-2 fa fa-pencil btn btn-outline-secondary text-grey"
                      id="editBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content",display:"none" }}
                    >
                      &nbsp;Edit
                    </Link>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-trash"
                      id="deleteBtn"
                      role="button"
                      onClick={() => handleDeleteItem(`${itemDetails.id}`)}
                      style={{ height: "fit-content", width: "fit-content",display:"none" }}
                    >
                      &nbsp;Delete
                    </a>
                    <a
                      href="#"
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-comments"
                      id="commentBtn"
                      role="button"
                      style={{
                       display:"none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      data-toggle="modal"
                      data-target="#commentModal"
                    >
                      &nbsp;Comment
                    </a>
                    <Link
                      to={`/loanholderhistory/${itemId}/`}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-history"
                      id="historyBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" ,display:"none"}}
                    >
                      &nbsp;History
                    </Link>
                  </div>
                </div>
              </div>
              <a  style={{display:"none"}}>
              <center>
                <h3
                  className="card-title"
                  style={{ textTransform: "Uppercase" }}
                >
                  {itemDetails.name}
                </h3>
                {itemDetails.status == "Inactive" ? (
                  <h6
                    className="blinking-text"
                    style={{ color: "red", width: "140px", fontWeight: "bold" }}
                  >
                    INACTIVE
                  </h6>
                ) : (
                  <h6
                    style={{
                      width: "140px",
                      color: "green",
                      fontWeight: "bold",
                      
                    }}
                  >
                    ACTIVE
                  </h6>
                )}
              </center>
              </a>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="overview" style={{ display: "none" }}>
              <div
                className="row g-0"
                style={{ marginLeft: "1px", marginRight: "1px" }}
              >
                <div className="col-lg-6">
                  <div className="history_highlight px-4 pt-4 d-flex">
                    <div className="col-8 d-flex justify-content-start">
                      {history.action == "Created" ? (
                        <p
                          className="text-success"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Created by :
                        </p>
                      ) : (
                        <p
                          className="text-warning"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Last Edited by :
                        </p>
                      )}
                      <span
                        className="ml-2"
                        style={{ fontSize: "1.15rem", fontWeight: "500" }}
                      >
                        {history.doneBy}
                      </span>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <span>{history.date}</span>
                    </div>
                  </div>
                  <div className="p-5 pt-2">
                    <center>
                      <h4>LOAN DETAILS </h4>
                    </center>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}> Status </label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            textTransform: "Uppercase",
                          }}
                        >
                          {itemDetails.status}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}> Loan Amount </label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            textTransform: "Uppercase",
                          }}
                        >
                          {itemDetails.loan_amount}
                        </p>
                      </div>
                    </div>

                   
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Loan Date</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            textTransform: "Uppercase",
                          }}
                        >
                          {itemDetails.date}
                        </p>
                      </div>
                    </div>

                   
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>
                          Lender Bank
                        </label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            textTransform: "Uppercase",
                          }}
                        >
                           {itemDetails.lenderbank}
                        </p>
                      </div>
                    </div>

                    {/* {% if itemDetails.inventory_account != "" %} */}
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Loan Recieved In</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                        >
                          {itemDetails.loan_recieved}
                        </p>
                      </div>
                    </div>
                    {/* {% endif %} */}


                    {itemDetails.cheque_number &&(
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Cheque Number</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p id="p1"
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                      
                        >
                          {itemDetails.cheque_number}
                        </p>
                      </div>
                    </div>
                    )}

{itemDetails.rec_upi &&(
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>UPI ID</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p id="p1"
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                      
                        >
                          {itemDetails.rec_upi}
                        </p>
                      </div>
                    </div>
                    )}

{itemDetails.rec_account_number  &&(
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Account Number</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p id="p1"
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                      
                        >
                          {itemDetails.rec_account_number}
                        </p>
                      </div>
                    </div>
                    )}





<div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Loan Payed From</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                        >
                          {itemDetails.loan_pay}
                        </p>
                      </div>
                    </div>
                    {/* {% endif %} */}


                    {itemDetails.pay_upi &&(
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>UPI</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p id="p1"
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                      
                        >
                          {itemDetails.pay_upi}
                        </p>
                      </div>
                    </div>
                    )}



{itemDetails.pay_account_number &&(
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Account Number</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p id="p1"
                          style={{
                            color: "white",
                            fontSize: "15px",
                            
                          }}
                      
                        >
                          {itemDetails.pay_account_number}
                        </p>
                      </div>
                    </div>
                    )}
</div>
</div>
                   
                <div
                  className="col-md-6"
                  style={{
                    backgroundColor: "rgba(22,37,50,255)",
                    borderTopRightRadius: "2vh",
                    borderBottomRightRadius: "2vh",
                  }}
                >
                  <div className="px-5 py-4">
                    <center>
                      <h4>ADDITIONAL DETAILS </h4>
                    </center>
                    <hr />

                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Holder</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                           
                          }}
                        >
                      {itemDetails.account_name} 
                        </p>
                      </div>
                    </div>
                    

                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Account Number</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                           
                          }}
                        >
                         {itemDetails.account_number}  
                        </p>
                      </div>
                    </div>


                   
                    
                    
                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Interest</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                           
                          }}
                        >
                          {itemDetails.intrest}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-4 d-flex justify-content-start">
                        <label style={{ color: "white" }}>Processing Fee</label>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <p>:</p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p
                          style={{
                            color: "white",
                            fontSize: "15px",
                           
                          }}
                        >
                          {itemDetails.process_fee} 
                        </p>
                      </div>
                    </div>



                    {itemDetails.term && (
  <div className="row mb-3">
    <div className="col-4 d-flex justify-content-start">
      <label style={{ color: "white" }}>Terms</label>
    </div>
    <div className="col-4 d-flex justify-content-center">
      <p>:</p>
    </div>
    <div className="col-4 d-flex justify-content-start">
      <p
        style={{
          color: "white",
          fontSize: "15px",
        }}
      >
        {itemDetails.term}
      </p>
    </div>
  </div>
)}
                    
                    

                    
                    </div>
                    </div>
              </div>
            </div>

            <div id="Transaction" >
              <div id="">
                <center>
                  <h3 className="mt-3 text-uppercase">
                    {itemDetails.account_name}  - TRANSACTIONS
                  </h3>
                </center>
                <div className="row mt-3">
                  <div className="col d-flex justify-content-between px-5 d-flex justify-content-end d-flex d-flex justify-content-end d-flex justify-content-end d-flex flex-column flex-lg-row">
                    <div className="item_data">
                    
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder="Search.."
                      autoComplete="off"
                      onKeyUp={searchTable}
                    />
                    </div>
                    <p>
                        Account Number: <span id="stockValue">{itemDetails.account_number}</span>
                      </p>
                     
                    </div>
                    <div className="item_data">
                    <p>
                        Holder Name: <span id="stockValue">{itemDetails.account_name}</span>
                      </p>
                      <p>
                        Balance: <span id="stockValue">{finalBal ? finalBal : finalBalance}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div  className="table-responsive px-2 mt-4">
                  <table id="itemTable" className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Sl No.</th>
                        <th className="text-center">PARTICULARS</th>
                        <th className="text-center">AMOUNT</th>
                        <th className="text-center">INTEREST</th>
                        <th className="text-center">TOTAL AMOUNT</th>
                        <th className="text-center">BALANCE</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                    {trans &&
                      trans.map((h, index) => (
                        <tr>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>{h.bank_type}</td>
                          <td style={{ textAlign: "center" }}>
                          {h.bank_type === 'PROCESSING FEE' ? h.loan.process_fee : h.loan_amount}
                        </td>
                        <td style={{ textAlign: "center" }}>
                  {h.bank_type === 'EMI PAID' || h.bank_type === 'OPENING BAL' ? h.loan_intrest : 0}
                </td> 
                <td style={{ textAlign: "center" }}>
  {h.bank_type === 'PROCESSING FEE' 
    ? h.loan.process_fee 
    : h.bank_type === 'OPENING BAL' 
      ? h.balance 
      : h.total}
</td>
                            <td style={{ textAlign: "center" }}>{h.balance}</td>
                           
                           <td>
                           
                    <button
                      type="button"
                      style={{ width: 'fit-content', height: 'fit-content' }}
                      className="btn btn-outline-secondary text-grey"
                      data-bs-toggle="dropdown" // Use data-bs-toggle instead of data-toggle for Bootstrap 5
                      aria-expanded="false" // For accessibility
                    >
                     <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleActionClick(h, 'edit')}
                        
                      >
                        Edit
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleActionClick(h, 'history')}
                      >
                        History
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleActionClick(h, 'delete')}
                      >
                        Delete
                       </a>
                    </div>
                 
                           </td>



                           
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>


            <div id="statement" style={{ display: "none" }}>
  <div className="container mt-3">
    <div id="printStatement">
      <div className="text-center mb-4">
        <h3 style={{ color: 'white' }}>
          {itemDetails.account_name}  - STATEMENT
        </h3>
      </div>

      <section className="date-range mb-4">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </section>

      <div id="printOnly">
        <div className="row" style={{ padding: '10px' }}>
          <div className="col d-flex justify-content-center">
            <div id="whatToPrint" style={{ backgroundColor: '#fff', padding: '1rem', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
              <div className="my-5 page">
                <div className="">
                  <div className="d-flex justify-content-between">
                  <section className="top-content bb d-flex justify-content-between align-items-center mb-2">
                  <div style={{textTransform: 'uppercase',fontSize:'16px', color: '#000000', backgroundColor: '#22b8d1', padding: '5px 10px', borderRadius: '5px'}}>
                    {itemDetails.status}
                  </div>
                  </section>

                  <section className="top-content bb d-flex justify-content-between align-items-center mb-2">
                    <div className="top-left text-center text-md-left">
                      <div style={{ textTransform: 'uppercase',fontSize:'16px', color: '#000000', backgroundColor: '#22b8d1', padding: '5px 10px', borderRadius: '5px' }}>
                        STATEMENT
                      </div>
                    </div>
                   
                  </section>
                  </div>

                  <section className="store-user mb-4" style={{ borderTop: '2px solid #000000', borderBottom: '2px solid #000000', paddingBottom: '1rem' }}>
                    <div className="row">
                      <div className="col-12 col-md-9">
                        <p style={{ color: '#000000', marginLeft: '20PX' }}>
                          Company Details,<br />
                          {compDetails.Company_name}<br />
                          {compDetails.Address}<br />
                          {compDetails.City} {compDetails.State}<br />
                          {compDetails.Pincode}<br />
                        </p>
                      </div>
                      <div className="col-12 col-md-3">
                        <p style={{ color: '#000000', margin: '0' }}>
                          Holder Details,<br />
                          {itemDetails.account_name}<br />
                          {itemDetails.account_number}<br />
                         
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="store-user mb-4" style={{ borderBottom: '2px solid #000000', paddingBottom: '1rem' }}>
                    <div className="row">
                      <div className="col-12 col-md-9">
                        <p style={{ color: '#000000', marginLeft: '20px' }}>Loan Amount</p>
                        <h5 style={{ color: '#000000', marginLeft: '20px' }}>{itemDetails.loan_amount}</h5>
                      </div>
                      <div className="col-12 col-md-3">
                        <p style={{ color: '#000000', margin: '0' }}>Balance</p>
                        <h5 style={{ color: '#000000', margin: '0' }}>{finalBal ? finalBal : finalBalance}</h5>
                      </div>
                    </div>
                  </section>

                  <section className="product-area mb-4">
                    <table className="table table-responsive-md table-hover">
                      <thead style={{ backgroundColor: '#22b8d1' }}>
                        <tr>
                          <th><h6 style={{ color: 'black' }}><b>SL.NO</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>DATE</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>PARTICULARS</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>AMOUNT</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>INTEREST</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>TOTAL AMOUNT</b></h6></th>
                          <th><h6 style={{ color: 'black' }}><b>BALANCE</b></h6></th>
                        </tr>
                      </thead>
                      <tbody style={{ color: '#000000' }}>
                      {trans1 &&
                      trans1.map((h, index) => (
                        <tr>
                          <td style={{ textAlign: "center",color:'black' }}>{index + 1}</td>
                          <td style={{ textAlign: "center",color:'black' }}>{h.loan_date}</td>
                          <td style={{ textAlign: "center",color:'black' }}>{h.bank_type}</td>
                          <td style={{ textAlign: "center",color:'black' }}>
                          {h.bank_type === 'PROCESSING FEE' ? h.loan.process_fee : h.loan_amount}
                        </td>
                        <td style={{ textAlign: "center",color:'black' }}>
                  {h.bank_type === 'EMI PAID' || h.bank_type === 'OPENING BAL' ? h.loan_intrest : 0}
                </td> 
                          <td style={{ textAlign: "center",color:'black' }}>
  {h.bank_type === 'PROCESSING FEE' 
    ? h.loan.process_fee 
    : h.bank_type === 'OPENING BAL' 
      ? h.balance 
      : h.total}
</td>
                            <td style={{ textAlign: "center",color:'black' }}>{h.balance}</td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </section>

                  <img src="cart.jpg" alt="" style={{ marginTop: '2rem' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></div></div></div>



      {/* <!-- Share To Email Modal --> */}
     
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Item Transactions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                    
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      

      {/* <!-- Add Comments Modal --> */}
      <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveItemComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comment}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>



     {/* <!-- Attach File Modal --> */}
     <div className="modal fade" id="attachFileModal">
        <div className="modal-dialog">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Attach File</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="fileModalDismiss"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={handleFileModalSubmit}
              method="post"
              encType="multipart/form-data"
              className="needs-validation px-1"
            >
              <div className="modal-body w-100">
                <div className="card p-3">
                  <div className="form-group">
                    <label for="emailIds">Input File</label>
                    <input
                      type="file"
                      className="form-control"
                      name="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      id="fileAttachInput"
                      required
                    />
                  </div>
                </div>
              </div>
              <div
                className="modal-footer d-flex justify-content-center w-100"
                style={{ borderTop: "1px solid #ffffff" }}
              >
                <button
                  type="submit"
                  className="submitShareEmailBtn w-50 text-uppercase"
                 
                >
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>



    </>
  );
}

export default ViewLoanHolder;
