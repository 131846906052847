import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import html2pdf from "html2pdf.js";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/PaymentReport.css";
function Employee_Loan_Statement() {
    const ID = Cookies.get('Login_id');
    const [loan,setLoan] = useState([]);
    const [totloan,setTotloan] = useState([]);
    const [totbal,setTotbal] = useState([]);
    const [comp,setCompany] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
        } else {
            contentDiv.style.display = "block";
        }
    }

    const Fetch_Employee_Loan_Statement = () =>{
        axios.get(`${config.base_url}/Fetch_Employee_Loan_Statement/${ID}/`).then((res)=>{
            if(res.data.status){
                setLoan(res.data.ldata);
                setTotloan(res.data.totloan);
                setTotbal(res.data.totbal);
                setCompany(res.data.cmp);
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
    };
    useEffect(()=>{
        Fetch_Employee_Loan_Statement();
      },[])
      function reportPDF(){
        var element = document.getElementById('printReport');
        var opt =
        {
            margin: [0.5, 0.3, 0.3, 0.5],
            filename: 'Employee_Loan_Statement_Report',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    }
    function ExportToExcel(){
        const Table = document.getElementById("reportTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Employee_Loan_Statement_Report.xlsx");
    }
    function printSheet() {
        var divToPrint = document.getElementById("printReport");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }
      const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;
  const [employee,setEmployee] = useState("");
  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      emp:employee
    };

    axios
      .post(`${config.base_url}/Employee_Loan_Statement_Customized/`, det)
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
            setLoan(res.data.ldata);
            setTotloan(res.data.totloan);
            setTotbal(res.data.totbal);
            setCompany(res.data.cmp);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var empl = employee;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          var em = {
            Id: ID,
            start: st,
            end: end,
            emp:empl,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(
              `${config.base_url}/Share_Employee_Loan_Statement/`,
              em
            )
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
    return(
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
        <div className="card radius-15">
    <div className="card-body">
        <div className="card-title" id="div2" style={{width:'fit-content'}}>
            <center>
                <h2 className="text-uppercase" id="headline">Employee Loan Report</h2>
                <b id="datefilter" style={{ display: 'none' }}></b>
            </center>
        </div>
        <hr />
        <div className="bar d-flex flex-column flex-md-row align-items-md-center justify-content-between">
            <div className="left d-flex justify-content-start mb-2 mb-md-0">
                <div className="position-relative mr-2">
                    <button className="btn btn-secondary" onClick={toggleContent} style={{ width: 'fit-content', height: 'fit-content' }}>
                        <i className="fa-solid fa fa-gear"></i> Customize Report
                    </button>
                    <div id="contentDiv" className="salescontent mt-2">
                        <h6>Customize Report</h6>
                        <form onSubmit={handleCustomize} className="form reportCustomizeForm px-1" method="get">
                            <div className="px-2 mb-2 w-100">
                                <label style={{ textAlign: 'left' }}>From:</label><br />
                                <input name="from_date" className="inputdate form-control" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="px-2 mb-2 w-100">
                                <label style={{ textAlign: 'left' }}>To:</label><br />
                                <input name="to_date" type="date" className="inputdate form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div className="px-2 mb-2 w-100">
                                <label style={{ textAlign: 'left' }}>Employee:</label><br />
                                <select name="employee" id="employeeValue" className="form-control" value={employee} onChange={(e) => setEmployee(e.target.value)}>
                                    <option value="All">All</option>
                                    {loan && loan.map((i) => (
                                        <option key={i.id} value={i.id}>{i.empname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex px-2 mt-3 mb-4 w-100">
                                <button type="submit" className="btn btn-outline-light" style={{ width: 'fit-content', height: 'fit-content' }}>Run Report</button>
                                <button type="reset" onClick={toggleContent} className="btn btn-outline-light ml-1 w-50" style={{ width: 'fit-content', height: 'fit-content' }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="right d-flex flex-wrap justify-content-center justify-content-md-end">
                <a className="btn btn-outline-secondary text-grey fa fa-file mb-2 mb-md-0" role="button" id="pdfBtn" onClick={reportPDF} style={{ width: 'fit-content', height: 'fit-content' }}>&nbsp;PDF</a>
                <a className="ml-2 btn btn-outline-secondary text-grey fa fa-print mb-2 mb-md-0" role="button" id="printBtn" onClick={printSheet} style={{ width: 'fit-content', height: 'fit-content' }}>&nbsp;Print</a>
                <a className="ml-2 btn btn-outline-secondary text-grey fa fa-table mb-2 mb-md-0" role="button" id="exportBtn" onClick={ExportToExcel} style={{ width: 'fit-content', height: 'fit-content' }}>&nbsp;Export</a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                    <li className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt mb-2 mb-md-0" data-toggle="dropdown" style={{ width: 'fit-content', height: 'fit-content' }}>&nbsp;Share</li>
                    <ul className="dropdown-menu mt-1" style={{ backgroundColor: 'black' }} id="listdiv">
                        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                            <li style={{ textAlign: 'center' }}>Whatsapp</li>
                        </a>
                        <li className="mb-2 ml-4" id="toggleEmailModal" style={{ cursor: 'pointer', color: '#e5e9ec', textAlign: 'center' }} data-toggle="modal" data-target="#shareToEmail">Email</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

    <div className="card radius-15 print-only" id="pdf-card">
        <div className="card-body">
            <div className="container-fluid">

                <div  id="printReport"  className="printReportTemplate" style={{display:'block'}}>
                    <div className="my-5 page" size="A4" >
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary" id="tableHead">
                                <div className="col-12" >
                                        <center className="h5 text-white" ><b style={{color:'white'}}>{comp}</b></center>
                                        <center className="h3 text-white"><b style={{color:'white'}}>Employee Loan Report</b></center>
                                        <center><b id="datefilter2" style={{color:'white'}}></b></center>
                                        
                                </div>  
                                
                            </div>
                            <div className="row px-1 py-1">
                                <div className="col-12">
                                    <section className="product-area mt-2">
                                        <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Sl.No.</th>
                                                    <th className="text-center">EMPLOYEE NAME</th>
                                                    <th className="text-center">EMPLOYEE NO.</th>
                                                    <th className="text-center">LOAN DATE</th>
                                                    <th className="text-center">DUE DATE</th>
                                                    <th className="text-center">LOAN AMOUNT</th>
                                                    <th className="text-center">BALANCE AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loan && loan.map((i,index) =>(
                                                    <tr>
                                                    <td style={{width:'5%'}} className="text-center">{ index+1 }</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.empname}</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.empno}</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.loandate}</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.duedate}</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.loan}</td>
                                                    <td style={{width:'16%'}} className="text-center">{i.balance}</td>
                                                </tr>
                                                ))}
                                                <tr>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="loanTotal">Total Loan Amount:{totloan}</td>
                                                  <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="BalTotal">Total Balance:{totbal}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        {/* {% if not loan %}
                                        <center><h4 className="text-dark">No data available.!</h4></center>
                                        {% endif %} */}
                                    </section>
                        
                                    {/* <section className="balance-info text-dark">
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row mb-2 d-flex justify-content-end">
                                                    <div className="mr-5 font-weight-bold">Total Loan Amount:{totloan} <p id="loanTotal" style={{fontSize:'25px'}}></p></div>
                                                    <div className="mr-5 font-weight-bold">Total Balance:{totbal} <p id="BalTotal" style={{fontSize:'25px'}}></p></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </section> */}
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Share To Email Modal --> */}
<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
    )
    
}
export default Employee_Loan_Statement;